import {attr, Model} from 'redux-orm';
import {types} from '../../../services/actionTypes';

interface PdfFileFields {
  contentFilePath: string;
  id: string;
  name: string;
}

// @ts-ignore
class PdfFile extends Model<typeof PdfFile, PdfFileFields> {
  // @ts-ignore
  // eslint-disable-next-line no-shadow
  static reducer(action, PdfFile, _session) {
    switch (action.type) {
      case types.UPSERT_PDFFILE:
        PdfFile.upsert(action.payload);
        break;
      case types.DELETE_PDFFILE:
        PdfFile.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }

  static modelName = 'PdfFile' as const;

  static fields = {
    id: attr(),
    name: attr(),
    contentFilePath: attr(),
  };

  static options = {
    idAttribute: 'id' as const,
  };
}

export default PdfFile;
