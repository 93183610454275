export enum Navigations {
  AndroidPermissionComponent = 'AndroidPermissionComponent',
  ExperienceManagerListComponent = 'ExperienceManagerListComponent',
  // ActivationComponent = 'ActivationComponent',
  ExperienceManagerEditComponent = 'ExperienceManagerEditComponent',
  ListComponent = 'ListComponent',
  MapComponent = 'MapComponent',
  MenuComponent = 'MenuComponent',
  NavNodeComponent = 'NavNodeComponent',
  PageComponent = 'PageComponent',
  PanoComponent = 'PanoComponent',
  PdfFileComponent = 'PdfFileComponent',
  RNPermissionComponent = 'RNPermissionComponent',
  SettingsComponent = 'SettingsComponent',
  WelcomeComponent = 'WelcomeComponent',
}

export enum SliceNames {
  app = 'app',
  experience = 'experience',
  experienceDownload = 'experienceDownload',
  experienceManager = 'experienceManager',
  navnode = 'navnode',
}

export enum AppStatus {
  Start = 'Start',
  Activated = 'Activated',
}

export enum ExperienceDownloadKeys {
  downloadExperienceId = 'downloadExperienceId',
  downloadJobId = 'downloadJobId',
  downloadPercentage = 'downloadPercentage',
  downloadStatus = 'downloadStatus',
  navigation = 'navigation',
}

export enum ExperienceDownloadStatus {
  Start = 'ExpDownloadStart',
  Loading = 'ExpDownloadLoading',
  Success = 'ExpDownloadSuccess',
  Failure = 'ExpDownloadFailure',
  Cancelled = 'ExpDownloadCancelled',
}

export enum ExperienceManagerKeys {
  connectivityState = 'connectivityState',
  experienceManagerStatus = 'experienceManagerStatus',
  navigation = 'navigation',
  loadingExperienceId = 'loadingExperienceId',
}

export enum ExperienceManagerStatus {
  Start = 'ExpManagerDownloadStart',
  Loading = 'ExpManagerDownloadLoading',
  Success = 'ExpManagerDownloadSuccess',
  Failure = 'ExpManagerDownloadFailure',
  Cancelled = 'ExpManagerDownloadCancelled',
}

export enum NavNodeKeys {
  navNodeId = 'navNodeId',
  requestedNavNodeId = 'requestedNavNodeId',
  status = 'status',
  type = 'type',
  // Not NavNode Keys ????
  experienceId = 'experienceId',
}

export enum NavNodeStatus {
  Start = 'Start',
  Loading = 'Loading',
  Success = 'Success',
  Failure = 'Failure',
  Cancelled = 'Cancelled',
}

export enum NavNodeTypes {
  list = 'list',
  map = 'map',
  menu = 'menu',
  page = 'page',
  pano = 'pano',
  pdf_file = 'pdf_file',
  footer = 'footer',
  main = 'main',
}

export enum ExperienceSenders {
  listRefreshExperiences = 'ExperienceManagerListComponent - refreshExperienceVersions',
  navigateToExperienceManagerEditComponent = 'ExperienceManagerListComponent - navigateToExperienceManagerEditComponent',
  editRefreshExperiences = 'ExperienceManagerEditComponent - refreshExperienceVersions',
  experienceManager_activateHandler = 'ExperienceManager - saga - experienceManager_activateHandler',
}
