import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExperienceManagerKeys, ExperienceManagerStatus, SliceNames } from '../../services/enums';
import { IHazelAction } from '../app/slice';

export interface IExperienceManagerUpdateKeyValue extends IHazelAction {
  key: ExperienceManagerKeys;
  value: string | null;
}

export interface IExperienceManager extends IHazelAction {
  connectivityState: boolean | null;
  experienceManagerStatus: ExperienceManagerStatus;
  loadingExperienceId: string | null;
  navigation: any | null;
}

const initialState: IExperienceManager = {
  experienceManagerStatus: ExperienceManagerStatus.Success,
  connectivityState: true,
  loadingExperienceId: null,
  navigation: null,
};

const experienceManagerSlice = createSlice({
  name: SliceNames.experienceManager,
  initialState,
  reducers: {
    updateStore(state, action: PayloadAction<IExperienceManagerUpdateKeyValue>) {
      const { key } = action.payload;
      state[key] = action.payload.value;
    },
  },
});

export const experienceManagerReducer = experienceManagerSlice.reducer;

export const { updateStore: experienceManager_updateStore } = experienceManagerSlice.actions;
