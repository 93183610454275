import React, { FC } from 'react';
import { constants } from '../../../services/constants';
import orm from '../../../orm';
import { store } from '../../../configureStore';
import { useNavigate } from 'react-router';

const DEBUG = true;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

type Props = {
  navNodeId: string;
};

const PanoComponent = (props: Props) => {
  debugLog('Component: PanoComponent with props: ' + JSON.stringify(props, null, 4));

  const navigate = useNavigate();

  const { navNodeId } = props;

  const session = orm.session(store.getState().orm);
  const { Experience, NavNode, Pano } = session;

  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);
  const contentId = navNode.contentId;

  const experienceId = navNode.experienceId;
  // @ts-ignore
  const experience = Experience.withId(experienceId);

  // @ts-ignore
  const pano = Pano.withId(contentId);

  debugLog('PanoComponent - experienceId : ' + experienceId);
  debugLog('PanoComponent - experience : ' + JSON.stringify(experience, null, 2));
  debugLog('PanoComponent - navNodeId : ' + navNodeId);
  debugLog('PanoComponent - navNode: ' + JSON.stringify(navNode, null, 2));
  debugLog('PanoComponent - contentId : ' + contentId);
  debugLog('PanoComponent - pano : ' + JSON.stringify(pano, null, 2));

  debugLog('P1 - PanoComponent');

  // // @ts-ignore
  // const pano = Pano.withId(contentId);
  // const panoFilePath = pano.panoFilePath;
  // const tourHtmlFilePath = pano.tourHtmlFilePath;

  const externalUrlToPano = `${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}/${pano.tourHtmlFilePath}`;
  const externalUrlToPanoStartScene = `${externalUrlToPano}?startscene=${navNode.panoVersionStartSceneSlug}`;

  debugLog('externalUrlToPano : ' + externalUrlToPano);
  debugLog('externalUrlToPanoStartScene : ' + externalUrlToPanoStartScene);

  // check if it has a startsceneslug
  // if yes
  // . append to externalUrlToPano: '?startscene=`{startsceneplugs}

  const goBack = () => {
    if (navNode.parentId === null) {
      navigate(`/`);
    } else {
      navigate(`${process.env.REACT_APP_BASENAME}#nn=${navNode.parentId}`);
    }
  };

  return (
    <div>
      {/* <div>
        <button onClick={goBack}>Back</button>
      </div> */}
      {/* <iframe src={externalUrlToPano} width="800" height="600"></iframe> */}
      <div>
        {/* <iframe src={externalUrlToPanoStartScene} width="800" height="600"></iframe> */}
        {/* SUBTRACT NavBar height - 60px */}
        {/* TODO: make 60 a constant in constants*/}
        <iframe src={externalUrlToPanoStartScene} width={window.innerWidth} height={window.innerHeight - 60}></iframe>
      </div>
    </div>
  );
};

export default PanoComponent;
