export const types = {
  UPSERT_NAVNODE: 'UPSERT_NAVNODE',
  DELETE_NAVNODE: 'DELETE_NAVNODE',
  UPSERT_EXPERIENCE: 'UPSERT_EXPERIENCE',
  DELETE_EXPERIENCE: 'DELETE_EXPERIENCE',
  UPSERT_PAGE: 'UPSERT_PAGE',
  DELETE_PAGE: 'DELETE_PAGE',
  UPSERT_PANO: 'UPSERT_PANO',
  DELETE_PANO: 'DELETE_PANO',
  UPSERT_PDFFILE: 'UPSERT_PDFFILE',
  DELETE_PDFFILE: 'DELETE_PDFFILE',
  UPSERT_MAP: 'UPSERT_MAP',
  DELETE_MAP: 'DELETE_MAP',
};
