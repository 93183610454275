import React from 'react';
import { createHashRouter, useLocation, useNavigate, BrowserRouter, Route, Routes } from 'react-router-dom';

import { history } from './../../navigation/history';

import NavNodeComponent from '../navNode/NavNodeComponent';

const DEBUG = true;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

const AppComponent = () => {
  debugLog('Component: AppComponent with no props');

  // @ts-ignore
  history.navigate = useNavigate();
  // @ts-ignore
  history.location = useLocation();

  debugLog(`ENVIRONMENT : ${process.env.NODE_ENV} `);

  // <BrowserRouter basename="/build">
  // </BrowserRouter>
  return (
    <Routes>
      <Route path="/" element={<NavNodeComponent />} />
      <Route path="*" element={<NavNodeComponent />} />
      {/* <Route path="/navNode/:navNodeId" element={<NavNodeComponent />} />
      <Route path="*" element={<p>There's nothing here: 404!</p>} />  */}
    </Routes>
  );
};

export default AppComponent;
