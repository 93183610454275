import React from 'react';
import { useNavigate } from 'react-router';

import orm from '../../../orm';
import { store } from '../../../configureStore';
import { constants } from '../../../services/constants';

type Props = {
  // navigation: any;
  navNodeId: string;
};

const DEBUG = true;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

const PdfFileComponent = (props: Props) => {
  // const PdfFileComponent = (props: PdfFileComponentScreenProps) => {
  debugLog('Component: PdfFileComponent with props: ' + JSON.stringify(props, null, 4));

  const navigate = useNavigate();

  const { navNodeId } = props;

  const session = orm.session(store.getState().orm);
  const { Experience, NavNode, PdfFile } = session;
  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);
  const contentId = navNode.contentId;
  const experienceId = navNode.experienceId;
  // @ts-ignore
  const experience = Experience.withId(experienceId);

  // @ts-ignore
  const pdfFile = PdfFile.withId(contentId);
  const pdfFilePath = pdfFile.pdfFilePath;

  debugLog('PdfFileComponent - experienceId : ' + experienceId);
  debugLog('PdfFileComponent - experience : ' + JSON.stringify(experience, null, 2));
  debugLog('PdfFileComponent - navNodeId : ' + navNodeId);
  debugLog('PdfFileComponent - navNode: ' + JSON.stringify(navNode, null, 2));
  debugLog('PdfFileComponent - contentId : ' + contentId);
  debugLog('PdfFileComponent - pdfFile : ' + JSON.stringify(pdfFile, null, 2));

  const goBack = () => {
    if (navNode.parentId === null) {
      navigate(`/`);
    } else {
      navigate(`${process.env.REACT_APP_BASENAME}#nn=${navNode.parentId}`);
    }
  };

  const externalUrlToPdfFile = `${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}${experienceId}/${pdfFile.pdfFilePath}`;
  debugLog('externalUrlToPdfFile : ' + externalUrlToPdfFile);

  return (
    <div>
      {/* <div>
        <button onClick={goBack}>Back</button>
      </div> */}
      {/* <h5>Experience</h5>
      <h5>{JSON.stringify(experience, null, 2)}</h5>
      <h5>NavNode</h5>
      <h5>{JSON.stringify(navNode, null, 2)}</h5>
      <h5>pdfFile</h5>
      <h5>{JSON.stringify(pdfFile, null, 2)}</h5> */}

      <div>
        {/* <object type={'application/pdf'} data={externalUrlToPdfFile} width={'800px'} height={'600px'}></object> */}
        <object
          type={'application/pdf'}
          data={externalUrlToPdfFile}
          width={window.innerWidth}
          height={window.innerHeight}
        ></object>
        {/* <object type={'application/pdf'} data={externalUrlToPdfFile} width={'100vw'} height={'100vh'}></object> */}
        {/* <object type={'application/pdf'} data={externalUrlToPdfFile} width={'100%'} height={'100%'}></object> */}
      </div>
      <p>Below a PDF file</p>
    </div>
  );
};
// <Pdf source={source} style={[layout.pdf, {width, height}]} fitPolicy={0} />

export default PdfFileComponent;
