import React from 'react';

// import HeaderComponent from '../../../navigation/HeaderComponent';
import { constants } from '../../../services/constants';
import orm from '../../../orm';
import { store } from '../../../configureStore';
import { useNavigate } from 'react-router';
// import { Button } from 'bootstrap';

const DEBUG = true;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

type Props = {
  // navigation: any;
  navNodeId: string;
};

const PageComponent = (props: Props) => {
  debugLog('Component: PageComponent with props: ' + JSON.stringify(props, null, 4));

  const { navNodeId } = props;

  const session = orm.session(store.getState().orm);
  const { Experience, NavNode, Page } = session;
  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);
  const contentId = navNode.contentId;
  const experienceId = navNode.experienceId;
  // @ts-ignore
  const experience = Experience.withId(experienceId);

  debugLog('P2 - PageComponent');
  debugLog('PageComponent - navNodeId : ' + navNodeId);
  debugLog('PageComponent - navNode: ' + JSON.stringify(navNode, null, 2));
  debugLog('PageComponent - contentId : ' + contentId);
  debugLog('PageComponent - experienceId : ' + experienceId);
  debugLog('PageComponent - experience : ' + JSON.stringify(experience, null, 2));

  // @ts-ignore
  const page = Page.withId(contentId);
  const pageFilePath = page.pageFilePath;
  debugLog('P3 - PageComponent');
  debugLog('PageComponent - page : ' + JSON.stringify(page, null, 2));
  debugLog('PageComponent - pageFilePath : ' + JSON.stringify(pageFilePath, null, 2));
  const pageAbsoluteUrl = 'http://process.env.EXPERIENCE_CONTENT_BASE_URL/' + pageFilePath;

  const pageURL = `${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}/contents/page/${navNode.contentId}/index.html`;

  debugLog('pageURL : ' + pageURL);

  const navigate = useNavigate();

  const goBack = () => {
    if (navNode.parentId === null) {
      navigate(`/`);
    } else {
      navigate(`${process.env.REACT_APP_BASENAME}#nn=${navNode.parentId}`);
    }
  };

  return (
    <div>
      {/* <div>
        <button onClick={goBack}>Back</button>
      </div> */}

      {/* <iframe id="iFrameExample" title="iFrame Example" src={`${pageURL}`} width={'98%;'} height={'800px'}></iframe> */}
      <iframe
        id="iFrameExample"
        title="iFrame Example"
        src={`${pageURL}`}
        width={window.innerWidth}
        height={window.innerHeight}
      ></iframe>
      {/* <h5>Experience</h5>
      <h5>{navNodeId}</h5>
      <h5>{navNode.name}</h5>
      {mainItems.map((item: any) => {
        return (
          <div key={item.id}>
            <div>
              <h5>{item.id}</h5>
            </div>
            <div>
              <button onClick={() => selectChild(item.id)}>{item.name}</button>
            </div>

            <h5>{JSON.stringify(item, null, 2)}</h5>
          </div>
        );
      })} */}
    </div>
  );
};

export default PageComponent;
