import React, { FC, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { installedExperiencesOrmSelector } from '../experience/selectors';
import NavBarComponent from './NavBarComponent';
import ListComponent from './components/ListComponent';
import MapReactMapGLComponent from '../content/map/MapReactMapGLComponent';
import MenuComponent from './components/MenuComponent';
import orm from '../../orm';
import PageComponent from '../content/page/PageComponent';
import PanoComponent from '../content/pano/PanoComponent';
import PdfFileComponent from '../content/pdfFile/PdfFileComponent';
import { store } from '../../configureStore';
import { NavNodeTypes } from '../../services/enums';
import { useParams, useLocation, useNavigate } from 'react-router';
import { fetchExperience } from './refresh/refresh';
import { experienceManagerLoadingExperienceIdSelector } from '../experienceManager/selectors';
import { Button, Col, Container, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
// import { navNodeExperienceIdSelector, navNodeIdSelector } from './selectors';

// @ts-ignore
// import '/na4web/content_package/contents/page_shared/exp_theme_na_v1.css';

const DEBUG = true;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

type Props = {
  // status: any;
};

// const NavNodeComponent = (props: NavNodeComponentScreenProps): any => {
const NavNodeComponent = (props: Props): any => {
  debugLog('Component: NavNodeComponent with props: ' + JSON.stringify(props, null, 2));

  // to obtain the current URL fragment
  const navigate = useNavigate();
  const location = useLocation();
  const nnHash = location.hash;
  debugLog('NavNodeComponent nnHash : ' + nnHash);

  // const experienceRootNavNodeId: any = process.env.REACT_APP_experienceRootNavNodeId;
  // const experienceId: any = process.env.REACT_APP_experienceId;

  let experienceId: string = '';
  let navNodeId = nnHash.split('=')[1];
  debugLog('NavNodeComponent navNodeId : ' + navNodeId);
  if (navNodeId === null || navNodeId === undefined) {
    // @ts-ignore
    let allExperiences = installedExperiencesOrmSelector(store.getState());
    debugLog('NavNodeComponent allExperiences : ' + JSON.stringify(allExperiences, null, 2));
    if (allExperiences.length !== 0) {
      experienceId = allExperiences[0].id;
      debugLog('NavNodeComponent experienceId : ' + experienceId);
      navNodeId = allExperiences[0].startNavNodeId;
      debugLog('NavNodeComponent navNodeId : ' + navNodeId);
    }

    debugLog('Component: NavNodeComponent - allExperiences: ' + JSON.stringify(allExperiences, null, 2));
  }
  debugLog('Component: NavNodeComponent - FRAGMENT - navNodeId: ' + navNodeId);

  const loadedExperienceId = useSelector((state) => experienceManagerLoadingExperienceIdSelector(state));
  debugLog('NavNodeComponent - useSelector - loadedExperienceId : ' + loadedExperienceId);

  const session = orm.session(store.getState().orm);
  const { Experience, NavNode } = session;
  // @ts-ignore
  const currentExperience = Experience.withId(experienceId);
  debugLog('NavNodeComponent - currentExperience : ' + JSON.stringify(currentExperience, null, 2));

  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);

  useEffect(() => {
    // @ts-ignore
    const navNodeExists = NavNode.withId(navNodeId);
    debugLog('NavNodeComponent USE EFFECT START = navNodeExists : ' + JSON.stringify(navNodeExists, null, 2));
    if (navNode === null || navNode === undefined || navNodeId === undefined || navNodeId === null) {
      debugLog('NavNodeComponent USE EFFECT = navNodeExists : ' + JSON.stringify(navNodeExists, null, 2));
      debugLog('NavNodeComponent USE EFFECT - BEFORE FETCHING EXPERIENCE ');
      fetchExperience();
      debugLog('NavNodeComponent USE EFFECT - AFTER FETCHING EXPERIENCE ');
    }
    debugLog('NavNodeComponent END OF USEEFFECT');
    // }, [NavNode, navNodeId]);
  }, []);

  const renderFailure = () => null;

  const renderNavNode = (navNode: any) => {
    let type: NavNodeTypes | null = null;
    if (navNode === null) {
      type = null;
    } else {
      type = navNode.type;
    }

    let navNodeComponent: any = null;

    switch (type) {
      case null: {
        debugLog('NavNodeComponent - renderNavNode - type is NULL - NavNode Does Not Exist ');
        // @ts-ignore
        navNodeComponent = (
          <>
            <div>This Tour does not contain the item - Try another tour !!!</div>
          </>
        );
        break;
      }
      case NavNodeTypes.list: {
        debugLog('NavNodeComponent - type is ListComponent');
        // @ts-ignore
        navNodeComponent = <ListComponent navNodeId={navNodeId} />;
        break;
      }
      case NavNodeTypes.map: {
        debugLog('NavNodeComponent - type is MapComponent');
        // @ts-ignore
        // navNodeComponent = <MapComponent navNodeId={navNodeId} />;
        // @ts-ignore
        navNodeComponent = <MapReactMapGLComponent navNodeId={navNodeId} />;
        break;
      }
      case NavNodeTypes.menu: {
        debugLog('NavNodeComponent - type is Menu');
        // @ts-ignore
        navNodeComponent = <MenuComponent navNodeId={navNodeId} />;
        break;
      }
      case NavNodeTypes.page: {
        debugLog('NavNodeComponent - type is PageComponent');
        // @ts-ignore
        navNodeComponent = <PageComponent navNodeId={navNodeId} />;
        break;
      }
      case NavNodeTypes.pano: {
        debugLog('NavNodeComponent - type is PanoComponent');
        // @ts-ignore
        navNodeComponent = <PanoComponent navNodeId={navNodeId} />;
        break;
      }
      case NavNodeTypes.pdf_file: {
        debugLog('NavNodeComponent - type is PDF');
        // @ts-ignore
        navNodeComponent = <PdfFileComponent navNodeId={navNodeId} />;
        break;
      }
    }
    return navNodeComponent;
  };

  const renderSpinnerLoading = () => null;

  const renderSpinnerStart = () => null;

  let startAgainURL: string = '';
  if (currentExperience !== null && currentExperience !== undefined) {
    startAgainURL = `${process.env.REACT_APP_BASENAME}#nn=${currentExperience?.startNavNodeId}`;
  } else {
    startAgainURL = `${process.env.REACT_APP_BASENAME}`;
  }

  return (
    <>
      <NavBarComponent experienceId={experienceId} navNodeId={navNodeId} />
      {renderNavNode(navNode)}
    </>
  );
};

export default NavNodeComponent;
