import React from 'react';

const DEBUG: boolean = true;
const debugLog = (str: string) => {
  if (DEBUG) {
    console.log(str);
  }
};

// webColumns: 2,
// webMaxColumns: 3,
// webAspectRatio: 1.5,

export const applyWebMenuDesign = (menuAttrs: any) => {
  debugLog('webMenuDesign.ts - applyWebMenuDesign - START');

  debugLog('webMenuDesign.ts - menuAttrs BEFORE : ' + JSON.stringify(menuAttrs, null, 2));

  // const ORIENTATION = menuAttrs.screenWidth > menuAttrs.screenHeight ? 'LANDSCAPE' : 'PORTRAIT';
  // debugLog('applyMenuDesign - ORIENTATION : ' + ORIENTATION);

  debugLog('webMenuDesign.ts - menuAttrs.screenWidth : ' + menuAttrs.screenWidth);
  debugLog('webMenuDesign.ts - menuAttrs.screenHeight : ' + menuAttrs.screenHeight);

  // TODO: make menus in both orientations equal to portrait (smaller width) size
  const xReferenceButtonWidth =
    (menuAttrs.screenWidth - (menuAttrs.webColumns + 1) * menuAttrs.menuItemHorizontalGap) / menuAttrs.webColumns;
  const yReferenceButtonWidth =
    (menuAttrs.screenHeight - (menuAttrs.webColumns + 1) * menuAttrs.menuItemHorizontalGap) / menuAttrs.webColumns;
  debugLog('webMenuDesign.ts - xReferenceButtonWidth : ' + xReferenceButtonWidth);
  debugLog('webMenuDesign.ts - yReferenceButtonWidth : ' + yReferenceButtonWidth);

  // use width from portrait (i.e. smaller width)
  let referenceButtonWidth = Math.min(xReferenceButtonWidth, yReferenceButtonWidth);
  debugLog('webMenuDesign.ts -  referenceButtonWidth : ' + referenceButtonWidth);

  debugLog('webMenuDesign.ts - A - menuAttrs.webColumns : ' + menuAttrs.webColumns);
  debugLog('webMenuDesign.ts - A - menuAttrs.webMaxColumns : ' + menuAttrs.webMaxColumns);

  menuAttrs.webColumns = Math.floor(menuAttrs.screenWidth / (referenceButtonWidth + menuAttrs.menuItemHorizontalGap));
  debugLog('webMenuDesign.ts - B - menuAttrs.webColumns : ' + menuAttrs.webColumns);
  debugLog('webMenuDesign.ts - B - menuAttrs.webMaxColumns : ' + menuAttrs.webMaxColumns);
  debugLog('webMenuDesign.ts - calculated number of columns : ' + menuAttrs.webColumns);
  debugLog('webMenuDesign.ts - MAX web columns : ' + menuAttrs.webMaxColumns);

  if (menuAttrs.webColumns > menuAttrs.webMaxColumns) {
    menuAttrs.menuItemColumns = menuAttrs.webMaxColumns;
  } else {
    menuAttrs.menuItemColumns = menuAttrs.webColumns;
  }
  debugLog('webMenuDesign.ts - C - menuAttrs.webColumns : ' + menuAttrs.webColumns);
  debugLog('webMenuDesign.ts - C - menuAttrs.webMaxColumns : ' + menuAttrs.webMaxColumns);
  debugLog('webMenuDesign.ts - calculated menuAttrs.menuItemColumns : ' + menuAttrs.menuItemColumns);

  let referenceButtonHeight = referenceButtonWidth * menuAttrs.webAspectRatio;
  debugLog('webMenuDesign.ts - referenceButtonHeight : ' + referenceButtonHeight);

  menuAttrs.calculatedButtonWidth = referenceButtonWidth;
  menuAttrs.calculatedButtonHeight = referenceButtonHeight;

  menuAttrs.menuItemAspectRatio = menuAttrs.webAspectRatio;

  debugLog('webMenuDesign.ts - menuAttrs AFTER : ' + JSON.stringify(menuAttrs, null, 2));
};

export default applyWebMenuDesign;
