import {attr, fk, Model} from 'redux-orm';
import {types} from '../../services/actionTypes';

interface NavNodeFields {
  backgroundImageFile: string;
  childContext: any;
  contentId: string;
  experienceId: string;
  id: string;
  menuAttrs: string;
  name: string;
  panoVersionStartSceneSlug: string;
  parentId: string;
  type: string;
}

class NavNode extends Model<typeof NavNode, NavNodeFields> {
  // @ts-ignore

  static reducer(action, NavNode, _session) {
    switch (action.type) {
      case types.DELETE_NAVNODE:
        NavNode.withId(action.payload.id).delete();
        break;
      case types.UPSERT_NAVNODE:
        NavNode.upsert(action.payload);
        break;
      default:
        break;
    }
  }
}
NavNode.modelName = 'NavNode' as const;
NavNode.fields = {
  backgroundImageFile: attr({getDefault: () => null}),
  childContext: attr({getDefault: () => {}}),
  contentId: attr({getDefault: () => null}),
  experienceId: attr({getDefault: () => null}),
  id: attr({getDefault: () => null}),
  menuAttrs: attr({getDefault: () => 'Default MENU'}),
  name: attr({getDefault: () => 'Default NAME'}),
  panoVersionStartSceneSlugg: attr({getDefault: () => null}),
  parentId: fk({
    to: 'NavNode',
    as: 'parent',
    relatedName: 'children',
  }),
  type: attr({getDefault: () => 'Default TYPE'}),
};

NavNode.options = {
  idAttribute: 'id' as const,
};

export default NavNode;
