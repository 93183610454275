import {attr, Model} from 'redux-orm';
import {types} from '../../../services/actionTypes';

interface PanoFields {
  id: string;
  name: string;
  tourHtmlFilePath: string;
}

// @ts-ignore
class Pano extends Model<typeof Pano, PanoFields> {
  // @ts-ignore
  // eslint-disable-next-line no-shadow
  static reducer(action, Pano, _session) {
    switch (action.type) {
      case types.UPSERT_PANO:
        Pano.upsert(action.payload);
        break;
      case types.DELETE_PANO:
        Pano.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }

  static modelName = 'Pano' as const;

  static fields = {
    id: attr(),
    name: attr(),
    tourHtmlFilePath: attr(),
  };

  static options = {
    idAttribute: 'id' as const,
  };
}

export default Pano;
