/* eslint-disable no-template-curly-in-string */
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
// import maplibregl from 'maplibre-gl';
// import Map from 'react-map-gl/maplibre';

import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { getIcon } from './iconUtils/SelectedFontAwesome';

import {
  Map as ReactMapGL,
  Marker,
  LineLayer,
  Layer,
  Popup,
  Source,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  SymbolLayer,
  useMap,
} from 'react-map-gl/maplibre';
import type { FeatureCollection } from 'geojson';
import maplibregl, { LngLatBounds, LngLatLike, PossiblyEvaluatedValue } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
// import { layerMarkers, layerTrails, lineLayers } from './mapConfig';
import { layerTrails, lineLayers, polygonsLayer } from './mapConfig';

import { constants } from '../../../services/constants';
import orm from '../../../orm';
import { store } from '../../../configureStore';

import WifiLocalSVG from './../../../assets/wifi.svg';

import { prepareMarkersDataFromCMSGeojson, prepareGeojsonLineStringDefaultData, prepareGeojsonPolygonDefaultData, extractGeojsonLegend } from './extractGeojson';
import { useNavigate } from 'react-router';
import { current } from '@reduxjs/toolkit';

const DEBUG: boolean = true;
const debugLog = (str: string) => {
  if (DEBUG) {
    console.log(str);
  }
};

type MapProps = {
  navNodeId: string;
};

function MapReactMapGLComponent(props: MapProps) {
  const navigate = useNavigate();
  const { navNodeId } = props;
  const { myMap } = useMap();
  // const [currentZoom, setCurrentZoom] = React.useState(12);
  // debugLog('MapReactMapGLComponent myMap : ' + JSON.stringify(myMap, null, 2));
  // debugLog('MapReactMapGLComponent mymap.getCenter() : ' + JSON.stringify(mymap.getCenter(), null, 2));

  debugLog(`MapReactMapGLComponent - ***********************************************************************`);
  debugLog(`MapReactMapGLComponent - A`);
  const session = orm.session(store.getState().orm);
  const { Map, NavNode } = session;

  debugLog(`MapReactMapGLComponent - B`);
  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);
  const contentId = navNode.contentId;
  const experienceId = navNode.experienceId;
  // @ts-ignore
  debugLog(`MapReactMapGLComponent - C`);

  // GEOJSON
  // @ts-ignore
  const cmsGeojson = Map.withId(contentId);
  debugLog(`MapReactMapGLComponent - D`);
  // debugLog("FEATURES cmsGeojson.mapJson.geo_json.features : " + JSON.stringify(cmsGeojson.mapJson.geo_json.features, null, 2));
  // debugLog("FEATURES cmsGeojson.mapJson : " + JSON.stringify(cmsGeojson.mapJson, null, 2));
  debugLog('A1 Map.withID  ------------>');
  debugLog("Map.withId - cmsGeojson.mapJson : ");
  debugLog(JSON.stringify(cmsGeojson.mapJson, null, 2));
  // debugLog("FEATURES cmsGeojson.mapJson.geo_json : " + JSON.stringify(cmsGeojson.mapJson.geo_json, null, 2));
  debugLog('A2 ------------>');

  prepareGeojsonLineStringDefaultData(cmsGeojson);
  prepareGeojsonPolygonDefaultData(cmsGeojson);
  debugLog(`MapReactMapGLComponent - E`);
  const legend = extractGeojsonLegend(cmsGeojson);
  debugLog(`MapReactMapGLComponent - F`);

  debugLog(`CMS GEOJSON LEGEND ################################################################### START`);
  debugLog('LEGEND ATTRIBUTES : ' + JSON.stringify(legend, null, 2));
  // if (legend !== null && legend !== undefined && legend.legend_visibility === true) {
  //   debugLog(`MapReactMapGLComponent - G`);
  //   debugLog('LEGEND ATTRIBUTES : ' + JSON.stringify(legend, null, 2));
  //   debugLog(`legend_title_font_color :   rgba(${legend.legend_title_font_color})`);
  //   debugLog(`legend_title_font_size :  ` + Number(`${legend.legend_title_font_size}`));
  //   debugLog(`number of legend_components :  ` + Number(`${legend.legend_components.length}`));
  //   debugLog(`number of legend_components :  ` + legend.legend_components.length);
  //   debugLog(`component[0] :  ${JSON.stringify(legend.legend_components[0], null, 2)}`);
  // }
  debugLog(`CMS GEOJSON LEGEND ################################################################### END`);

  debugLog(`MapReactMapGLComponent - H`);
  debugLog('MapReactMapGLComponent -cmsGeojson = ' + cmsGeojson);
  debugLog(`MapReactMapGLComponent -CMS GEOJSON CONTENT ################################################################### START`);
  debugLog('MapReactMapGLComponent -JSON.stringify(cmsGeojson) = ' + JSON.stringify(cmsGeojson, null, 2));
  debugLog(`MapReactMapGLComponent -CMS GEOJSON CONTENT ################################################################### END`);

  debugLog(`MapReactMapGLComponent - I`);
  const markersData = prepareMarkersDataFromCMSGeojson(cmsGeojson);
  debugLog(`MapReactMapGLComponent - J`);
  debugLog(`MapReactMapGLComponent -markersData - ################################################################### START`);
  debugLog('MapReactMapGLComponent - markersData : ' + JSON.stringify(markersData, null, 2));
  debugLog(`MapReactMapGLComponent -markersData - ################################################################### END`);

  debugLog(`MapReactMapGLComponent - K`);
  // const [currentZoom, setCurrentZoom] = React.useState(Number(cmsGeojson.maxZoomFactor));
  const [currentZoom, setCurrentZoom] = React.useState(Number(cmsGeojson.minZoomFactor));
  const [popupId, setPopupId] = useState<string>('INITIAL NO POPUP');

  // Cluster Data - START
  // Cluster Data - START
  let clusterObject = { count: 0, longitude: 0, latitude: 0 };
  markersData.forEach((marker: any) => {
    if (marker.zoom_level > currentZoom) {
      clusterObject.count += 1;

      clusterObject.longitude += parseFloat(marker.coordinates.longitude);

      clusterObject.latitude += parseFloat(marker.coordinates.latitude);
    }
  });
  debugLog(`MapReactMapGLComponent - L`);

  if (clusterObject.count > 0) {
    debugLog(`MapReactMapGLComponent - M`);
    clusterObject.longitude /= clusterObject.count;
    clusterObject.latitude /= clusterObject.count;
  }
  // Cluster Data - END
  // Cluster Data - END

  // const linesData = extractLinesDataFromCMSGeojson(cmsGeojson);
  // debugLog('linesData : ' + JSON.stringify(linesData, null, 2));

  debugLog('MapReactMapGLComponent - experienceId : ' + experienceId);
  debugLog('MapReactMapGLComponent - navNodeId : ' + navNodeId);
  debugLog('MapReactMapGLComponent - navNode: ' + JSON.stringify(navNode, null, 2));
  debugLog('MapReactMapGLComponent - contentId : ' + contentId);
  // debugLog('MapReactMapGLComponent - cmsGeojson : ' + JSON.stringify(cmsGeojson, null, 2));

  const sw_lat: number = +cmsGeojson.swCornerLat;
  const sw_lon: number = +cmsGeojson.swCornerLon;
  const ne_lat: number = +cmsGeojson.neCornerLat;
  const ne_lon: number = +cmsGeojson.neCornerLon;

  debugLog(`MapReactMapGLComponent - N`);
  const initialCenterLatWeb = Number(cmsGeojson.initialCenterLatWeb);
  const initialCenterLonWeb = Number(cmsGeojson.initialCenterLonWeb);
  const initialZoomWeb = Number(cmsGeojson.initialZoomWeb);

  debugLog(`MapReactMapGLComponent - O`);
  debugLog("MapReactMapGLComponent -initialCenterLatWeb: " + JSON.stringify(initialCenterLatWeb, null, 2));
  debugLog(`MapReactMapGLComponent -initialCenterLatWeb:  ${initialCenterLatWeb}`);
  debugLog(`MapReactMapGLComponent -initialCenterLonWeb:  ${initialCenterLonWeb}`);
  debugLog(`MapReactMapGLComponent -initialZoomWeb:  ${initialZoomWeb}`);

  let maxZoomFactor: number = Number(cmsGeojson.maxZoomFactor);
  let minZoomFactor: number = Number(cmsGeojson.minZoomFactor);

  debugLog(`MapReactMapGLComponent - P`);
  let ne: LngLatLike = new maplibregl.LngLat(ne_lon, ne_lat);
  let sw: LngLatLike = new maplibregl.LngLat(sw_lon, sw_lat);
  let mapBounds: LngLatBounds = new maplibregl.LngLatBounds(sw, ne);
  debugLog(`MapReactMapGLComponent - R`);

  const mapTilesURL = `${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}/contents/map/${cmsGeojson.id}/tiles/{z}/{x}/{y}.jpg`;

  const mapStyle = {
    version: 8,
    glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
    sources: {
      'raster-tiles': {
        type: 'raster',
        // tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tiles: [mapTilesURL],
        tileSize: 256,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      },
    },
    layers: [
      {
        id: 'simple-tiles',
        type: 'raster',
        source: 'raster-tiles',
      },
    ],
  };



  const selectChild = (childNavNodeId: string) => {
    debugLog(`MapReactMapGLComponent - Q`);
    // store.dispatch(navNode_select({ navNodeId: childNavNodeId }));
    navigate(`${process.env.REACT_APP_BASENAME}#nn=${childNavNodeId}`);
  };

  const geojson: FeatureCollection = {
    // id: 'MapGeoJSON',
    type: 'FeatureCollection',
    features: cmsGeojson.mapJson.geo_json.features,
  };


  debugLog(`MapReactMapGLComponent - S`);
  const zoomIn = () => {
    debugLog(`MapReactMapGLComponent - T`);
    let zoomNow = currentZoom;
    debugLog('MapReactMapGLComponent - zoomIn() - currentZoom : ' + currentZoom);
    debugLog('MapReactMapGLComponent - zoomIn() - zoomNow: ' + zoomNow);
    setCurrentZoom(zoomNow + 1);
  };

  // const showViewState = useCallback((evt: any) => {
  const showViewState = (evt: any) => {
    // debugLog('onMove evt : ' + JSON.stringify(evt, null, 2));
    debugLog(`MapReactMapGLComponent - U`);
    debugLog('MapReactMapGLComponent -onMove evt : ');
    debugLog('MapReactMapGLComponent -onMove evt.viewState : ' + JSON.stringify(evt.viewState, null, 2));
    debugLog('MapReactMapGLComponent -onMove evt.viewState - popupId : ' + popupId);
    // setViewState({ zoom: evt.viewState.zoom });
    let zoomNow: number = evt.viewState.zoom;
    zoomNow = Math.ceil(zoomNow);
    if (zoomNow !== currentZoom) {
      debugLog('MapReactMapGLComponent -zoomNow : ' + zoomNow);
      debugLog('MapReactMapGLComponent -popupId : ' + popupId);
      setCurrentZoom(zoomNow);
    }
    // }, []);
  };


  // DEBUGGING SECTION
  debugLog(`MapReactMapGLComponent - extracted marker data ^^^^^^^^^^^^^^^^^^^^^^^^^^ START`);
  markersData.forEach((marker: any, index: number) => {
    debugLog(`MapReactMapGLComponent -marker `);
    debugLog(`MapReactMapGLComponent -marker : zoom_level =  ${marker.zoom_level}`);
    debugLog(`MapReactMapGLComponent -marker : annotation_zoom_level =  ${marker.annotation_zoom_level}`);
    debugLog(`MapReactMapGLComponent -marker : annotation =  ${marker.annotation}`);
    debugLog(`MapReactMapGLComponent -marker : content_type =  ${marker.content_type}`);
    //   debugLog(`marker : icon_type =  ${marker.icon_type}`);
    //   debugLog(`marker : icon_size =  ${marker.icon_size}`);
    //   debugLog(`marker : icon_color =  ${marker.icon_color}`);
    // debugLog(`marker : id =  ${marker.id}`);
    // debugLog(JSON.stringify(marker.callout_style_web, null, 2));
    // if (marker.callout_style_web !== null) {
    //   debugLog("color : " + marker.callout_style_web.color);
    // }
    // debugLog(`${marker.callout_html_str_web}`);
    // debugLog(JSON.stringify(marker.callout_html_str_web, null, 2));
  });
  debugLog(`MapReactMapGLComponent - extracted marker data ^^^^^^^^^^^^^^^^^^^^^^^^^^ END`);

  debugLog('MapReactMapGLComponent -popupId = ' + popupId);
  debugLog('MapReactMapGLComponent -currentZoom = ' + currentZoom);

  return (
    <div>
      {legend.legend_current_zoom_visibility &&
        <p>Current Zoom Level: {currentZoom}</p>
      }

      {/* {false && legend.legend_visibility === true && */}
      {legend.legend_visibility &&
        <div>


          <p style={{
            backgroundColor: `rgba(${legend.legend_background_color})`,
            color: `rgba(${legend.legend_title_font_color})`,
            fontSize: Number(`${legend.legend_title_font_size}`),
          }}>{legend.legend_title}
          </p>

          {/* {false && legend.legend_components.length > 0 &&
            legend.legend_components.map((legend_component: any) => {
              return (
                <>
                  <span>  </span>
                  <FontAwesomeIcon
                    style={{ color: `rgba(${legend_component.legend_icon_color})`, width: legend_component.legend_icon_size, height: legend_component.legend_icon_size }}
                    icon={getIcon(legend_component.legend_icon_type)}
                  />
                  <span>  </span>
                  <span style={{ color: `rgba(${legend_component.legend_text_color})` }}>
                    {legend_component.legend_text}
                  </span>
                  <span>  </span>
                  <span>  </span>
                </>);
            })
          } */}
        </div>
      }
      <ReactMapGL
        initialViewState={{
          // longitude: centerLon + 0.2,
          // latitude: centerLat,
          // /////////////////////////////////////////////
          // FRANK-GEOJSON
          // latitude: 49.72,
          // longitude: -125.02,
          // zoom: 11,
          // /////////////////////////////////////////////
          // ELKFALLS
          // latitude: 50.04,
          // longitude: -125.33,
          // zoom: 15,
          // /////////////////////////////////////////////
          // WWK-TREATY
          // latitude: 50.3,
          // longitude: -125.48,
          // zoom: 9,
          latitude: initialCenterLatWeb,
          longitude: initialCenterLonWeb,
          // longitude: -125.329,
          // latitude: 50.0357,
          // longitude: -125.32,
          // latitude: 50.045,
          // zoom: 8,
          zoom: initialZoomWeb
          // bounds: mapBounds,
          // @ts-ignore
          // minZoom: minZoomFactor,
          // @ts-ignore
          // maxZoom: maxZoomFactor,
        }}
        // longitude={centerLon}
        // latitude={centerLat}
        // WWK-TREATY
        // minZoom={9}
        // maxZoom={14}
        // /////////////////////////////////////////////
        // ELKFALLS
        // minZoom={13}
        // maxZoom={18}
        // /////////////////////////////////////////////
        // FRANK-GEOJSON
        // minZoom={12}
        // maxZoom={16}
        minZoom={minZoomFactor}
        maxZoom={maxZoomFactor}
        onMove={(evt) => showViewState(evt)}
        maxBounds={mapBounds}
        style={{
          width: '100%',
          //height: '100%',
          // height: window.outerHeight,
          // height: window.outerHeight - 380,
          height: '95vh',
          // height: 1000,
          position: 'relative',
          top: 0,
          bottom: 0,
        }}
        // @ts-ignore
        mapStyle={Object.freeze(mapStyle)}
      >
        {markersData.length > 0 &&
          currentZoom >= cmsGeojson.minZoomFactor &&
          markersData.map((marker: any, index: number) => {
            return (
              <>
                {currentZoom >= marker.zoom_level && marker.callout_html_str_web.length > 0 && marker.id === popupId && (
                  <Popup
                    key={marker.id}
                    longitude={marker.coordinates.longitude}
                    latitude={marker.coordinates.latitude}
                    closeButton={true}
                    closeOnClick={true}
                    onClose={() => { setPopupId('NO POPUP NOW') }}
                    // style={{ color: 'blue', fontSize: 25 }}
                    // style={{ color: "blue", fontSize: 25 }}
                    // style={ color: "blue", fontSize: 25 }}
                    style={marker.callout_style_web}
                    anchor={'top'}
                  >
                    {marker.callout_html_str_web}
                  </Popup >
                )}
              </>)
          })
        }


        {
          markersData.length > 0 &&
          currentZoom >= cmsGeojson.minZoomFactor &&
          markersData.map((marker: any, index: number) => {
            return (
              <>
                {marker.zoom_level <= currentZoom && (
                  <Marker
                    key={marker.id}
                    longitude={marker.coordinates.longitude}
                    latitude={marker.coordinates.latitude}
                    // anchor="bottom"
                    // anchor="center"
                    // anchor="top"
                    anchor="top"
                    offset={[0, -0.8 * marker.icon_size]}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    // color="green"
                    onClick={(e) => {
                      if (marker.target_nav_node_id !== undefined && marker.target_nav_node_id !== null && marker.target_nav_node_id.length > 4) {
                        selectChild(marker.target_nav_node_id);
                      }
                      if (marker.callout_html_str_web !== undefined && marker.callout_html_str_web !== null) {
                        e.originalEvent.stopPropagation();
                        setPopupId(marker.id);
                        debugLog('MARKER ID : ' + marker.id);
                      }
                    }}
                  >

                    {(marker.content_type !== 'annotation') &&
                      <FontAwesomeIcon
                        style={{ color: `rgba(${marker.icon_color})`, width: marker.icon_size, height: marker.icon_size }}
                        icon={getIcon(marker.icon_type)}
                      />
                    }

                    {marker.annotation_zoom_level !== null &&
                      marker.annotation_zoom_level !== undefined &&
                      currentZoom >= parseInt(marker.annotation_zoom_level) &&
                      (marker.content_type === 'both' || marker.content_type === 'annotation') && (
                        <p style={{ color: `rgba(${marker.an_color})`, fontSize: marker.an_size }}>{marker.annotation}</p>
                      )}
                  </Marker>
                )}
              </>
            );
          })
        }
        {/* {clusterObject.count > 0 && (
        <Marker
          longitude={clusterObject.longitude}
          latitude={clusterObject.latitude}
          onClick={() => {
            zoomIn();
          }}
        >
          <FontAwesomeIcon
            style={{ position: 'relative', color: `rgba(237, 147, 11, 0.8)`, width: 75, height: 75 }}
            icon={getIcon('fa-solid fa-circle')}
          />
          <p
            style={{
              position: 'absolute',
              top: '35%',
              left: `${clusterObject.count < 10 ? '40' : '25'}%`,
              color: `rgba(200, 200, 200, 1.0)`,
              fontSize: 30,
            }}
          >
            {clusterObject.count}
          </p>
        </Marker>
      )} */}

        < Source id="source-for-line-data-id" type="geojson" data={geojson} >
          {/* <Layer key={99999} {...layerTrails} /> */}
          {/* <Layer key={99999} {...lineLayers[0]} /> */}
          <Layer key={lineLayers[0].id} {...lineLayers[0]} />
          <Layer key={lineLayers[1].id} {...lineLayers[1]} />
          <Layer key={lineLayers[2].id} {...lineLayers[2]} />
          {/* {lineLayers.length > 0 &&
          lineLayers.map((layer, id: number) => {
            return (
              <>
                <Layer key={id} {...layer} />
              </>
            );
          })} */}
        </Source>
        <Source id="source-for-polygon-data-id" type="geojson" data={geojson}>
          <Layer key={polygonsLayer.id} {...polygonsLayer} />
        </Source>
        {/* <Source id="line-test" type="geojson" data={testLineGeojson}>
        <Layer key={lineLayers[1].id} {...lineLayers[4]} />
        <Layer key={lineLayers[2].id} {...lineLayers[5]} />
      </Source> */}
        <NavigationControl />
      </ReactMapGL>



    </div >
  );
}

export default MapReactMapGLComponent;
