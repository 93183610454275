import type { LayerProps } from 'react-map-gl/maplibre';
import type { LineLayer, SymbolLayer } from 'react-map-gl/maplibre';
import type { FillLayer } from 'react-map-gl/maplibre';
import debugLog from '../../../debugUtil';

export const lineLayers: any[] = [
  {
    id: 'line-dashed-small',
    type: 'line',
    // source: 'geojson',
    paint: {
      'line-color': ['concat', 'rgba(', ['get', 'line_stroke_color'], ')'],
      'line-width': ['get', 'line_stroke_width'],
      // 'line-dasharray': [1, 1],
    },
    filter: ['<', ['get', 'line_stroke_width'], 3],
  },
  {
    id: 'line-dashed-medium',
    type: 'line',
    // source: 'geojson',
    paint: {
      'line-color': ['concat', 'rgba(', ['get', 'line_stroke_color'], ')'],
      'line-width': ['get', 'line_stroke_width'],
      // 'line-dasharray': [2, 2],
    },
    filter: ['==', ['get', 'line_stroke_width'], 3],
  },
  {
    id: 'line-dashed-large',
    type: 'line',
    // source: 'geojson',
    paint: {
      'line-color': ['concat', 'rgba(', ['get', 'line_stroke_color'], ')'],
      'line-width': ['get', 'line_stroke_width'],
      // 'line-dasharray': [2, 2],
    },
    filter: ['>', ['get', 'line_stroke_width'], 3],
  },
  {
    id: 'test-line-dashed-small-1',
    type: 'line',
    // source: 'geojson',
    paint: {
      'line-color': ['concat', 'rgba(', ['get', 'line_stroke_color'], ')'],
      'line-width': ['get', 'line_stroke_width'],
      'line-dasharray': [1, 1],
    },
    filter: ['<', ['get', 'line_stroke_width'], 3],
  },
  {
    id: 'test-line-dashed-medium-1',
    type: 'line',
    // source: 'geojson',
    paint: {
      'line-color': ['concat', 'rgba(', ['get', 'line_stroke_color'], ')'],
      'line-width': ['get', 'line_stroke_width'],
      'line-dasharray': [2, 2],
    },
    filter: ['==', ['get', 'line_stroke_width'], 3],
  },
  {
    id: 'test-line-dashed-large-1',
    type: 'line',
    // source: 'geojson',
    paint: {
      'line-color': ['concat', 'rgba(', ['get', 'line_stroke_color'], ')'],
      'line-width': ['get', 'line_stroke_width'],
      'line-dasharray': [2, 2],
    },
    filter: ['>', ['get', 'line_stroke_width'], 3],
  },
];

export const polygonsLayer: FillLayer = {
  id: 'polygonLayerId',
  source: 'geojson',
  type: 'fill',
  // layout: {},
  paint: {
    // 'fill-outline-color': ['concat', 'rgba(', '0,0,0,0.9', ')'],
    // 'fill-outline-color': ['concat', 'rgba(', ['get', 'pol_stroke_color'], ')'],
    // 'fill-outline-color': 'rgba(255, 255, 255, 0.9)',
    // 'fill-color': 'rgba(0, 255, 0, 0.5)',
    'fill-color': ['concat', 'rgba(', ['get', 'pol_fill_color'], ')'],
    // 'fill-color': '#008', // blue color fill
    // 'fill-opacity': 1.0,
    // 'fill-opacity': ['get', 'pol_opacity'],
    'fill-antialias': true,
  },
};

// export const layerTrails: LineLayer = {
export const layerTrails = {
  id: 'trails',
  type: 'line',
  // type: 'geojson',
  // source: 'MapGeoJSON',
  // source: 'geojson',
  minzoom: 8,
  // minzoom: 14,
  // maxzoom: 16,
  // filter: ['==', 'class', 'LineString'],
  // filter: ['==', 'type', 'LineString'],
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  //   filter: ['==', ['get', 'USE_BIKE'], 'Yes'],
  paint: {
    // 'line-color': '#22ff55',
    // 'line-color': ['get', 'line_stroke_color'],
    // 'line-color': `rgba(${['get', 'line_stroke_color']})`,
    // 'line-color': rgba`(${['get', 'line_stroke_color']})`,
    // "line-color": "rgba(255, 255, 0, 1)",
    // @ts-ignore
    // 'line-color': ['to-rgba', ['get', 'line_stroke_color']],
    'line-color': ['concat', 'rgba(', ['get', 'line_stroke_color'], ')'],
    // 'line-color': ['rgba', ['get', 'line_stroke_color'] ],
    // ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
    // 'line-width': 2,
    'line-width': ['get', 'line_stroke_width'],
    // 'line-dasharray': [1, 2],
    //'line-dasharray': [ 'array', 'string', 2, [ ['get', 'line_dash_length'], ['get', 'line_dash_space'], ], ],
    // 'line-dasharray': [ 'array', 2, [ ['get', 'line_dash_length'], ['get', 'line_dash_space'], ], ],
    // 'line-dasharray': ['array', ['number', ['get', 'line_dash_length']], ['number', ['get', 'line_dash_space']]],
    // @ts-ignore
    // 'line-dasharray': ['array', ['concat', '[', ['to-number',['get', 'line_stroke_color']], ',', ['to-number', [get', 'line_dash_space']], ']']], },
    // 'line-dasharray': ['array', ['to-number', ['get', 'line_stroke_color']], ['to-number', ['get', 'line_dash_space']]],
    // 'line-dasharray': ['array', 'number', '[1, 2]'],
    'line-dasharray': [2, 2],
  },
};

// export const layerMarkers: SymbolLayer = {
//   id: 'markers',
//   type: 'symbol',
//   // type: 'geojson',
//   // source: 'MapGeoJSON',
//   // source: 'geojson',
//   minzoom: 14,
//   maxzoom: 18,
//   // 'source-layer': 'landuse',
//   // filter: ['==', 'class', 'LineString'],
//   // filter: ['==', 'type', 'LineString'],
//   layout: {
//     // 'icon-image': 'wifi-icon',
//     // 'icon-image': 'gradient',
//     // 'icon-image': 'custom-marker',
//     // get the year from the source's "year" property
//     // 'text-field': ['get', 'year'],
//     // 'text-field': ['get', 'annotation'],
//     'text-field': 'annotation',
//     'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
//     'text-offset': [0, 1.25],
//     'text-anchor': 'top',
//     'text-size': 12,
//   },
//   paint: {
//     'text-color': '#f02',
//   },
//   filter: ['==', '$type', 'Point'],
// };

// export const clusterCountLayer: LayerProps = {
//   id: 'cluster-count',
//   type: 'symbol',
//   // source: 'earthquakes',
//   filter: ['has', 'point_count'],
//   layout: {
//     'text-field': '{point_count_abbreviated}',
//     'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
//     'text-size': 12,
//   },
// };

// export const unclusteredPointLayer: LayerProps = {
//   id: 'unclustered-point',
//   type: 'circle',
//   // source: 'earthquakes',
//   filter: ['!', ['has', 'point_count']],
//   paint: {
//     'circle-color': '#11b4da',
//     'circle-radius': 4,
//     'circle-stroke-width': 1,
//     'circle-stroke-color': '#fff',
//   },
// };
