// import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
// import {faArrowDownToSquare, faFileXmark} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowDownToSquare,
  faGear,
  faCampground,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleInfo,
  faCirclePlay,
  faCrosshairs,
  faDroneFront,
  faFileXmark,
  faFish,
  faHandPointUp,
  faHouse,
  faLocationArrow,
  faLocationDot,
  faMap,
  faMapLocationDot,
  faPersonBiking,
  faPersonHiking,
  faRectangleXmark,
  faRestroom,
  faSignHanging,
  faSignsPost,
  faSquareParking,
  faTablePicnic,
  faWifi,
} from '@fortawesome/pro-solid-svg-icons';
import { faHouseBlank, faMapPin, faPanorama, faRouteHighway } from '@fortawesome/pro-regular-svg-icons';

export const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'fa-regular fa-location-crosshairs':
      return faCrosshairs;
    case 'fa-regular fa-house-blank':
      return faHouseBlank;
    case 'fa-regular fa-map':
      return faMap;
    case 'fa-regular fa-map-location-dot':
      return faMapLocationDot;
    case 'fa-regular fa-map-pin':
      return faMapPin;
    case 'fa-regular fa-panorama':
      return faPanorama;
    case 'fa-regular fa-route-highway':
      return faRouteHighway;
    case 'fa-solid fa-arrow-down-to-square':
      return faArrowDownToSquare;
    case 'fa-solid fa-campground':
      return faCampground;
    case 'fa-solid fa-chevron-left':
      return faChevronLeft;
    case 'fa-solid fa-chevron-right':
      return faChevronRight;
    case 'fa-solid fa-circle':
      return faCircle;
    case 'fa-solid fa-circle-info':
      return faCircleInfo;
    case 'fa-solid fa-circle-play':
      return faCirclePlay;
    case 'fa-solid fa-drone-front':
      return faDroneFront;
    case 'fa-solid fa-file-xmark':
      return faFileXmark;
    case 'fa-solid fa-fish':
      return faFish;
    case 'fa-solid fa-gear':
      return faGear;
    case 'fa-solid fa-house':
      return faHouse;
    case 'fa-solid fa-location-arrow':
      return faLocationArrow;
    case 'fa-solid fa-location-dot':
      return faLocationDot;
    case 'fa-solid fa-map':
      return faMap;
    case 'fa-solid fa-map-location-dot':
      return faMapLocationDot;
    case 'fa-solid fa-person-biking':
      return faPersonBiking;
    case 'fa-solid fa-person-hiking':
      return faPersonHiking;
    case 'fa-solid fa-rectangle-xmark':
      return faRectangleXmark;
    case 'fa-solid fa-restroom':
      return faRestroom;
    case 'fa-solid fa-square-parking':
      return faSquareParking;
    case 'fa-solid fa-sign-hanging':
      return faSignHanging;
    case 'fa-solid fa-signs-post':
      return faSignsPost;
    case 'fa-solid fa-table-picnic':
      return faTablePicnic;
    case 'fa-solid fa-wifi':
      return faWifi;
    default:
      return faHandPointUp;
  }
};

export default getIcon;
