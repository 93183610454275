import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import './fonts/BarlowCondensed-Black.ttf';
import './fonts/BarlowCondensed-BlackItalic.ttf';
import './fonts/BarlowCondensed-Bold.ttf';
import './fonts/BarlowCondensed-BoldItalic.ttf';
import './fonts/BarlowCondensed-ExtraBold.ttf';
import './fonts/BarlowCondensed-ExtraBoldItalic.ttf';
import './fonts/BarlowCondensed-ExtraLight.ttf';
import './fonts/BarlowCondensed-ExtraLightItalic.ttf';
import './fonts/BarlowCondensed-Italic.ttf';
import './fonts/BarlowCondensed-Light.ttf';
import './fonts/BarlowCondensed-LightItalic.ttf';
import './fonts/BarlowCondensed-Medium.ttf';
import './fonts/BarlowCondensed-MediumItalic.ttf';
import './fonts/BarlowCondensed-Regular.ttf';
import './fonts/BarlowCondensed-SemiBold.ttf';
import './fonts/BarlowCondensed-SemiBoldItalic.ttf';
import './fonts/BarlowCondensed-Thin.ttf';
import './fonts/BarlowCondensed-ThinItalic.ttf';
import './fonts/Lexend-Black.ttf';
import './fonts/Lexend-Bold.ttf';
import './fonts/Lexend-ExtraBold.ttf';
import './fonts/Lexend-ExtraLight.ttf';
import './fonts/Lexend-Light.ttf';
import './fonts/Lexend-Medium.ttf';
import './fonts/Lexend-Regular.ttf';
import './fonts/Lexend-SemiBold.ttf';
import './fonts/Lexend-Thin.ttf';
import './fonts/LondrinaSolid-Black.ttf';
import './fonts/LondrinaSolid-Light.ttf';
import './fonts/LondrinaSolid-Regular.ttf';
import './fonts/LondrinaSolid-Thin.ttf';
import './fonts/Raleway-Black.ttf';
import './fonts/Raleway-BlackItalic.ttf';
import './fonts/Raleway-Bold.ttf';
import './fonts/Raleway-BoldItalic.ttf';
import './fonts/Raleway-ExtraBold.ttf';
import './fonts/Raleway-ExtraBoldItalic.ttf';
import './fonts/Raleway-ExtraLight.ttf';
import './fonts/Raleway-ExtraLightItalic.ttf';
import './fonts/Raleway-Italic.ttf';
import './fonts/Raleway-Light.ttf';
import './fonts/Raleway-LightItalic.ttf';
import './fonts/Raleway-Medium.ttf';
import './fonts/Raleway-MediumItalic.ttf';
import './fonts/Raleway-Regular.ttf';
import './fonts/Raleway-SemiBold.ttf';
import './fonts/Raleway-SemiBoldItalic.ttf';
import './fonts/Raleway-Thin.ttf';
import './fonts/Raleway-ThinItalic.ttf';
import './fonts/UbuntuCondensed-Regular.ttf';
import './fonts/YanoneKaffeesatz-Bold.ttf';
import './fonts/YanoneKaffeesatz-ExtraLight.ttf';
import './fonts/YanoneKaffeesatz-Light.ttf';
import './fonts/YanoneKaffeesatz-Medium.ttf';
import './fonts/YanoneKaffeesatz-Regular.ttf';
import './fonts/YanoneKaffeesatz-SemiBold.ttf';

// import App from './App';
// import App from './src/features/app/AppComponent';
import AppComponent from './features/app/AppComponent';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// import { PersistGate } from 'redux-persist/integration/react';
// import { persistor, store } from './configureStore';
import { store } from './configureStore';

import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// <React.StrictMode>
// </React.StrictMode>
//      <PersistGate loading={null} persistor={persistor}>
//      </PersistGate>

// <BrowserRouter basename="/build"></BrowserRouter>
root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME_ERROR}>
      <AppComponent />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
