import {attr, Model} from 'redux-orm';
import {types} from '../../services/actionTypes';

interface ExperienceFields {
  backgroundImagePath: string | null;
  childContext: string | null;
  experienceParentId: string | null;
  description: string | null;
  downloadUrl: string | null;
  fileSize: number;
  id: string;
  installed: boolean;
  logoImageFile: string | null;
  name: string;
  rootNavNodeId: string;
  startNavNodeId: string;
  version: string | null;
  versionInstalled: string | null;
}

// @ts-ignore
class Experience extends Model<typeof Experience, ExperienceFields> {
  // @ts-ignore

  static reducer(action: any, Experience, _session: any) {
    switch (action.type) {
      case types.DELETE_EXPERIENCE:
        Experience.withId(action.payload.id).delete();
        break;
      case types.UPSERT_EXPERIENCE:
        Experience.upsert(action.payload);
        break;
      default:
        break;
    }
  }

  static modelName = 'Experience' as const;

  static fields = {
    backgroundImagePath: attr({
      getDefault: () => 'Default BACKGROUND IMAGE PATH',
    }),
    childContext: attr({
      getDefault: () => 'Default CHILDCONTEXT',
    }),
    experienceParentId: attr(),
    description: attr(),
    downloadUrl: attr(),
    fileSize: attr(),
    id: attr(),
    installed: attr({getDefault: () => false}),
    logoImageFile: attr({getDefault: () => 'Default LOGO_IMAGE_FILE'}),
    name: attr({getDefault: () => 'Default NAME'}),
    rootNavNodeId: attr(),
    startNavNodeId: attr(),
    version: attr({getDefault: () => 'Default VERSION'}),
    versionInstalled: attr({getDefault: () => 'Default VERSION INSTALLED'}),
  };

  static options = {
    idAttribute: 'id' as const,
  };
}

export default Experience;
