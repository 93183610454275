import React, { FC, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { installedExperiencesOrmSelector } from '../experience/selectors';
import ListComponent from './components/ListComponent';
import MapReactMapGLComponent from '../content/map/MapReactMapGLComponent';
import MenuComponent from './components/MenuComponent';
import orm from '../../orm';
import PageComponent from '../content/page/PageComponent';
import PanoComponent from '../content/pano/PanoComponent';
import PdfFileComponent from '../content/pdfFile/PdfFileComponent';
import { store } from '../../configureStore';
import { NavNodeTypes } from '../../services/enums';
import { useParams, useLocation, useNavigate } from 'react-router';
import { fetchExperience } from './refresh/refresh';
import { experienceManagerLoadingExperienceIdSelector } from '../experienceManager/selectors';
import { Button, Col, Container, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import getIcon from '../content/map/iconUtils/SelectedFontAwesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { navNodeExperienceIdSelector, navNodeIdSelector } from './selectors';

const DEBUG = true;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

type Props = {
  navNodeId: string | null;
  experienceId: string | null;
};

const NavBarComponent = (props: Props): any => {
  debugLog('Component: NavBarComponent with props: ' + JSON.stringify(props, null, 2));

  const navNodeId = props.navNodeId;

  // to obtain the current URL fragment
  const navigate = useNavigate();
  const location = useLocation();

  const session = orm.session(store.getState().orm);
  const { Experience, NavNode } = session;

  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);
  // @ts-ignore
  const currentExperience = Experience.withId(props.experienceId);

  let isHomePage: boolean = false;
  if (currentExperience !== null && currentExperience !== undefined) {
    isHomePage = currentExperience.rootNavNodeId === navNodeId;
  }

  let startAgainURL: string = '';
  if (currentExperience !== null && currentExperience !== undefined) {
    startAgainURL = `${process.env.REACT_APP_BASENAME}#nn=${currentExperience?.startNavNodeId}`;
  } else {
    startAgainURL = `${process.env.REACT_APP_BASENAME}`;
  }

  return (
    <>
      <Navbar
        // expand="lg"
        // className="justify-content-between navbar navbar-dark bg-dark navbar-fixed-top"
        // className="justify-content-between navbar navbar-fixed-top "
        className={
          isHomePage === true
            ? 'justify-content-center navbar navbar-fixed-top'
            : 'justify-content-between navbar navbar-fixed-top'
        }
        style={{ backgroundColor: '#44403c', position: 'sticky', top: 0, height: 40 }}
      >
        {isHomePage === false && (
          <Button
            className="d-flex align-items-center"
            style={{
              // border: '4px solid red',
              padding: 0,
              paddingLeft: 5,
            }}
            variant="transparent"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FontAwesomeIcon
              style={{
                position: 'relative',
                color: `rgba(255, 255, 255, 1.0)`,
                backgroundColor: `rgba(73, 112, 104, 0.0)`,
                boxShadow: 'none',
                width: 27,
                height: 27,
              }}
              icon={getIcon('fa-solid fa-chevron-left')}
            />
          </Button>
        )}

        <Navbar.Brand
          style={{
            color: 'white',
            fontSize: 40,
          }}
        >
          {/* {navNode !== null && navNode !== undefined && navNode.name} */}
        </Navbar.Brand>

        {isHomePage === false && (
          <Button
            className="d-flex align-items-center"
            style={{
              // border: '4px solid red',
              padding: 0,
              paddingRight: 10,
            }}
            variant="transparent"
            onClick={() => {
              navigate(startAgainURL);
            }}
          >
            <FontAwesomeIcon
              style={{
                position: 'relative',
                color: `rgba(255, 255, 255, 1.0)`,
                backgroundColor: `rgba(73, 112, 104, 0.0)`,
                boxShadow: 'none',
                width: 27,
                height: 27,
              }}
              icon={getIcon('fa-regular fa-house-blank')}
            />
          </Button>
        )}
      </Navbar>
    </>
  );
};

export default NavBarComponent;
