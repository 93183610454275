import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'react-bootstrap';

import orm from '../../../orm';
import { store } from '../../../configureStore';
import { NavNodeTypes } from '../../../services/enums';

const DEBUG = false;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

type Props = {
  // navigation: any;
  navNodeId: string;
};

const ListComponent = (props: Props) => {
  // const ListComponent = (props: ListComponentScreenProps) => {
  debugLog('Component: NavNode:ListComponent with props: ' + JSON.stringify(props, null, 4));
  const { navNodeId } = props;

  const navigate = useNavigate();

  const session = orm.session(store.getState().orm);
  const { Experience, NavNode } = session;
  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);
  const contentId = navNode.contentId;
  const experienceId = navNode.experienceId;
  // @ts-ignore
  const experience = Experience.withId(experienceId);

  debugLog('ListComponent');
  debugLog('ListComponent - navNodeId : ' + navNodeId);
  debugLog('ListComponent - navNode: ' + JSON.stringify(navNode, null, 2));
  debugLog('ListComponent - contentId : ' + contentId);
  debugLog('ListComponent - experienceId : ' + experienceId);
  debugLog('ListComponent - experience : ' + JSON.stringify(experience, null, 2));

  // debugLog('MenuComponent - experience.backgroundImageFile : ' + experience.backgroundImageFile);
  debugLog('ListComponent - experience.backgroundImageFile : ' + experience.backgroundImageFile);
  debugLog('ListComponent - navNode.backgroundImageFile : ' + navNode.backgroundImageFile);
  debugLog('ListComponent - navNode : ' + JSON.stringify(navNode, null, 2));

  let mainItems: (typeof NavNode)[] = [];
  let footerItems: (typeof NavNode)[] = [];
  navNode.children.toModelArray().forEach((element: any) => {
    switch (element.childContext.slot) {
      case NavNodeTypes.footer:
        footerItems.push(element);
        break;
      case NavNodeTypes.main:
        mainItems.push(element);
        break;
      default:
        throw new Error(`Handle this: ${element}`);
    }
  });

  const selectChild = (childNavNodeId: string) => {
    navigate(`/${process.env.REACT_APP_BASENAME}#nn=${childNavNodeId}`);
  };

  const goBack = () => {
    if (navNode.parentId === null) {
      navigate(`/`);
    } else {
      navigate(`/${process.env.REACT_APP_BASENAME}#nn=${navNode.parentId}`);
    }
  };

  return (
    <div>
      {/* <div>
        <button onClick={goBack}>Back</button>
      </div> */}
      {/* <h5>{navNodeId}</h5>
      <h5>{navNode.name}</h5>
      <h5>{JSON.stringify(navNode, null, 2)}</h5>
      <h5>{mainItems.length}</h5>
      <h5>mainItems</h5>
      <h5>{JSON.stringify(mainItems, null, 2)}</h5> */}
      <div>
        {mainItems.map((item: any) => {
          return (
            <div key={item.id}>
              <div>
                <Button
                  style={{
                    width: '200px',
                    height: '150px',
                    margin: 5,
                  }}
                  onClick={() => selectChild(item.id)}
                >
                  {item.name}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  // return (
  //   <Box>
  //     <NavigationBar navigation={props.navigation} title={navNode.name} right={true} />
  //     <VStack space={2} alignItems="center">
  //       <Text m={10} fontSize={'2xl'}>
  //         {navNode.name}
  //       </Text>
  //     </VStack>
  //     <ScrollView>
  //       {listItems.map((childNavNode: any) => (
  //         <Pressable m={'4'} key={childNavNode.id} onPress={selectChild(childNavNode.id)}>
  //           <HStack space={4}>
  //             <Text w="90%">{childNavNode.name}</Text>
  //             <FontAwesomeIcon size={20} name="chevron-right" />
  //           </HStack>
  //           <Divider my="2" />
  //         </Pressable>
  //       ))}
  //     </ScrollView>
  //   </Box>
  // );
};

export default ListComponent;
