const DEBUG: boolean = true;
const debugLog = (str: string) => {
  if (DEBUG) {
    console.log(str);
  }
};

// prepare GeoJSON for maplibre/reactmapgl
export const prepareGeojsonPolygonDefaultData = (cmsGeojson: any) => {
  cmsGeojson.mapJson.geo_json.features.map((feature: any) => {
    if (feature.geometry.type === 'Polygon') {
      if (feature.properties.pol_fill_color === null || feature.properties.pol_fill_color === undefined) {
        feature.properties.pol_fill_color = "255, 255, 255, 0.5";
      }
    }
  })
};

export const prepareGeojsonLineStringDefaultData = (cmsGeojson: any) => {
  cmsGeojson.mapJson.geo_json.features.map((feature: any) => {

    if (feature.geometry.type === 'LineString') {
      if (feature.properties.line_stroke_color === null || feature.properties.line_stroke_color === undefined) {
        feature.properties.line_stroke_color = "255, 255, 255, 1.0";
      }
      if (feature.properties.line_stroke_width === null || feature.properties.line_stroke_width === undefined) {
        feature.properties.line_stroke_width = 3;
      }
      feature.properties.pol_fill_color = "0, 0, 0, 0.0";
    }
  })
};

interface LegendType {
  legend_background_color?: string | null | undefined;
  legend_components?: any[] | null;
  legend_current_zoom_visibility?: boolean | null | undefined;
  legend_title?: string | null | undefined;
  legend_title_font_color?: string | null | undefined;
  legend_title_font_family?: string | null | undefined;
  legend_title_font_size?: number | null | undefined;
  legend_visibility?: boolean | null | undefined;
}

// prepare map legend
export const extractGeojsonLegend = (cmsGeojson: any) => {
  // let legend: { any: any } | null | boolean = null;
  let legend: LegendType | null = {
    legend_background_color: null,
    legend_components: null,
    legend_current_zoom_visibility: false,
    legend_title: null,
    legend_title_font_color: null,
    legend_title_font_family: null,
    legend_title_font_size: null,
    legend_visibility: false,
  };

  debugLog('extractGeojson - A - legend : ' + JSON.stringify(legend, null, 2));
  // const updatedLegend = cmsGeojson.mapJson.geo_json.features.map((feature: any) => {
  cmsGeojson.mapJson.geo_json.features.map((feature: any) => {
    if (feature.geometry.type === 'Legend') {
      // debugLog('extractGeojson - WE HAVE FOUND feature.geometry.type === Legend');

      // if (feature.properties.legend_visibility !== null && feature.properties.legend_visibility !== undefined && feature.properties.legend_visibility !== false) {
      //   legend = {
      //     legend_background_color: feature.properties.legend_background_color,
      //     legend_title: feature.properties.legend_title,
      //     legend_title_font_color: feature.properties.legend_title_font_color,
      //     legend_title_font_family: feature.properties.legend_title_font_family,
      //     legend_title_font_size: feature.properties.legend_title_font_size,
      //     legend_visibility: feature.properties.legend_visibility === null || feature.properties.legend_visibility === undefined || feature.properties.legend_visibility === false ? false : true,
      //     legend_components: feature.properties.legend_components !== null && feature.properties.legend_components !== undefined ? feature.properties.legend_components : []
      //   };
      // }
    }
  })
  return legend;
};

// POINT - MARKERS    START <---------------------------------> START
// const markers: MapMarker[] = [];

export const prepareMarkersDataFromCMSGeojson = (cmsGeojson: any) => {
  let markers: any[] = [];

  debugLog(
    'extractGeojson.ts -  cmsGeojson.mapJson.geo_json : ' + JSON.stringify(cmsGeojson.mapJson.geo_json, null, 2)
  );

  let index: number = 0;
  const updatedMarkers = cmsGeojson.mapJson.geo_json.features.map((feature: any) => {
    if (feature.geometry.type === 'Point' && feature.geometry.coordinates !== null) {

      let decideContentType: string | undefined | null = "";
      switch (feature.properties.content_type) {
        case 'both':
          decideContentType = 'both';
          break;
        case 'icon':
          decideContentType = 'icon';
          break;
        case 'annotation':
          decideContentType = 'annotation';
          break;
        case null:
        case undefined:
          decideContentType = 'both';
          break;
      }

      // @ts-ignore
      const marker: MapMarker = {
        annotation: feature.properties.annotation !== null && feature.properties.annotation !== undefined ? feature.properties.annotation : "missing",
        annotation_zoom_level: feature.properties.annotation_zoom_level !== null && feature.properties.annotation_zoom_level !== undefined ?
          feature.properties.annotation_zoom_level : 6,
        an_size: feature.properties.an_size !== null && feature.properties.an_size !== undefined ? feature.properties.an_size : 18,
        an_color: feature.properties.an_color !== null && feature.properties.an_color !== undefined ? feature.properties.an_color : "255, 255, 255, 1.0",

        category: feature.properties.category,

        // only for mobile app
        callout_html_str: feature.properties.callout_html_str === null ? '' : feature.properties.callout_html_str,
        callout_html_str_web: feature.properties.callout_html_str_web === null || feature.properties.callout_html_str_web === undefined ? '' : feature.properties.callout_html_str_web,
        callout_style_web: feature.properties.callout_style_web !== null && feature.properties.callout_style_web !== undefined ? feature.properties.callout_style_web :
          {
            "color": "rgba(0, 0, 0, 1.0)",
            "fontSize": 20,
            "textAlign": "center",
            "fontWeight": "bold",
            "borderRadius": 10,
            "paddingVertical": 10
          },
        // only for mobile app
        callout_width_percent: feature.properties.callout_width_percent === null ? 50 : Math.floor(feature.properties.callout_width_percent),

        content_type: decideContentType,

        coordinates: {
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
        },
        // only for mobile app
        icon_bg_color: feature.properties.icon_bg_color,
        // only for mobile app
        icon_btn_bg_color: feature.properties.icon_btn_bg_color,
        icon_color: feature.properties.icon_color !== null && feature.properties.icon_color !== undefined ? feature.properties.icon_color : "255, 255, 255, 1.0",
        icon_size: feature.properties.icon_size !== null && feature.properties.icon_size !== undefined ? feature.properties.icon_size : 25,
        icon_type: feature.properties.icon_type !== null && feature.properties.icon_type !== undefined ? feature.properties.icon_type : "unknown icon type",

        id:
          Math.floor(1000000 * feature.geometry.coordinates[0]).toString() +
          Math.floor(1000000 * feature.geometry.coordinates[1]).toString(),

        name: feature.properties.name,

        slug: feature.properties.slug,
        target_nav_node_id: feature.properties.target_nav_node_id,
        zoom_level:
          feature.properties.zoom_level !== null && feature.properties.zoom_level !== undefined
            ? feature.properties.zoom_level : 6
      };

      // Special case: to show annotation only zoom_level === annotation_zoom_level must be true
      if (marker.content_type === 'annotation') {
        marker.zoom_level = marker.annotation_zoom_level;
      }
      index = index + 1;
      debugLog('extractGeojson.ts - index : ' + index);
      debugLog('extractGeojson.ts - marker.zoom_level : ' + marker.zoom_level);
      markers.push(marker);
      debugLog('extractGeojson - marker : ' + JSON.stringify(marker, null, 2));
      //debugLog('INDIVIDUAL MARKER : ' + JSON.stringify(marker, null, 2));
    }
  });
  debugLog('Number of Markers : ' + markers.length);
  return markers;

};


export default prepareMarkersDataFromCMSGeojson;
