import { createSelector as createSelectorOrm } from 'redux-orm';
// import {map as ormMap} from 'redux-orm';
import { createSelector as createSelectorReselect } from 'reselect';

import orm from '../../orm';
// import Experience from './model';

const experienceSelector = (state: any) => state.experience;

export const experienceIdSelector = createSelectorReselect(experienceSelector, (experience) => experience.experienceId);

export const experienceRequestedNavNodeIdSelector = createSelectorReselect(
  experienceSelector,
  (experience) => experience.requestedNavNodeId
);

export const experienceStatusSelector = createSelectorReselect(experienceSelector, (experience) => experience.status);

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
////////  ORM SELECTORS !!!!
// export const allExperiencesOrmSelector = createSelectorOrm(orm.Experience);
// export const allExperiencesOrmSelector = createSelectorOrm(orm, Experience);

export const installedExperiencesOrmSelector = createSelectorOrm(orm, (session: any) => {
  const all = session.Experience.all()
    .toRefArray()
    .filter((experience: any) => experience.installed);
  return all;
});

export const availableExperiencesOrmSelector = createSelectorOrm(orm, (session: any) => {
  const all = session.Experience.all()
    .toRefArray()
    .filter((experience: any) => !experience.installed);
  return all;
});

export default experienceStatusSelector;
