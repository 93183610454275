import {attr, Model} from 'redux-orm';
import {types} from '../../../services/actionTypes';

interface PageFields {
  contentFilePath: string;
  id: string;
  name: string;
}

// @ts-ignore
class Page extends Model<typeof Page, PageFields> {
  // @ts-ignore
  // eslint-disable-next-line no-shadow
  static reducer(action, Page, _session) {
    switch (action.type) {
      case types.UPSERT_PAGE:
        Page.upsert(action.payload);
        break;
      case types.DELETE_PAGE:
        Page.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }

  static modelName = 'Page' as const;

  static fields = {
    id: attr(),
    name: attr(),
    contentFilePath: attr(),
  };

  static options = {
    idAttribute: 'id' as const,
  };
}

export default Page;
