import { ExperienceManagerKeys } from './../../../services/enums';
import axios from 'axios';

import { types } from '../../../services/actionTypes';
import { store } from '../../../configureStore';
import { experienceManager_updateStore } from '../../experienceManager/slice';

const DEBUG = false;
const debugLog = (msg: string) => {
  if (DEBUG) {
    console.log(msg);
  }
};

export async function fetchExperience() {
  try {
    const experienceURL = process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL + '/experience.json';
    debugLog('experienceURL : ' + experienceURL);

    // read/download experience.json
    //@ts-ignore
    let response = await axios.get(experienceURL);
    const experienceJson = response.data;
    debugLog('refresh.ts - experienceJSON: ' + JSON.stringify(experienceJson, null, 2));

    const maps = experienceJson.contents.map;
    const navNodes = experienceJson.navNodes;
    const pages = experienceJson.contents.page;
    const panos = experienceJson.contents.pano;
    const pdfFiles = experienceJson.contents.pdfFile;

    // create experience in orm
    const createExperienceAction = {
      type: types.UPSERT_EXPERIENCE,
      payload: {
        id: experienceJson.id,
        // childContext: experienceJson.childContext,
        childContext: navNodes[experienceJson.rootNavNodeId].childContext,
        description: experienceJson.description,
        experienceParentId: experienceJson.experienceParentId,
        installed: true,
        logoImageFile: experienceJson.logoImageFile,
        name: experienceJson.name,
        rootNavNodeId: experienceJson.rootNavNodeId,
        startNavNodeId: experienceJson.startNavNodeId,
        version: experienceJson.version,
      },
    };
    store.dispatch(createExperienceAction);

    debugLog('refresh.ts - refresh - MAPS : ' + maps);
    debugLog('refresh.ts - refresh - MAPS : ' + JSON.stringify(maps, null, 2));

    // navNodes
    const navNodeKeys = Object.keys(navNodes);
    debugLog('refresh.ts - navNodeKeys: ' + JSON.stringify(navNodeKeys, null, 4));

    navNodeKeys.forEach((key: string) => {
      const id = key;
      const backgroundImageFile = navNodes[key].backgroundImageFile;
      const childContext = navNodes[key].childContext;
      const name = navNodes[key].name;
      const menuAttrs = navNodes[key].menuAttrs;
      const panoVersionStartSceneSlug = navNodes[key].panoVersionStartSceneSlug;
      const parentId = navNodes[key].parentId;
      const type = navNodes[key].type;

      const createNavNodeAction = {
        type: types.UPSERT_NAVNODE,
        payload: {
          id: id,
          backgroundImageFile: backgroundImageFile,
          childContext: childContext || null,
          contentId: navNodes[key].contentId || null,
          experienceId: experienceJson.id,
          menuAttrs: menuAttrs,
          name: name,
          panoVersionStartSceneSlug: panoVersionStartSceneSlug || null,
          parentId: parentId,
          type: type,
        },
      };
      store.dispatch(createNavNodeAction);
    });

    // page
    const pageKeys = Object.keys(pages);
    debugLog('refresh.ts - pageKeys: ' + JSON.stringify(pageKeys, null, 4));

    pageKeys.forEach((key: string) => {
      const id = key;
      const name = pages[key].name;
      const pageFilePath = pages[key].contentFilePath;

      const createPageAction = {
        type: types.UPSERT_PAGE,
        payload: {
          id: id,
          name: name,
          pageFilePath: pageFilePath,
        },
      };
      store.dispatch(createPageAction);
    });

    // pano
    const panoKeys = Object.keys(panos);
    debugLog('refresh.ts - panoKeys: ' + JSON.stringify(panoKeys, null, 4));

    panoKeys.forEach((key: string) => {
      const id = key;
      const name = panos[key].name;
      const tourHtmlFilePath = panos[key].tourHtmlFilePath;

      const createPanoAction = {
        type: types.UPSERT_PANO,
        payload: {
          id: id,
          name: name,
          tourHtmlFilePath: tourHtmlFilePath,
        },
      };
      store.dispatch(createPanoAction);
    });

    // pdf
    const pdfFileKeys = Object.keys(pdfFiles);
    debugLog('refresh.ts - pdfFileKeys: ' + JSON.stringify(pdfFileKeys, null, 4));

    pdfFileKeys.forEach((key: string) => {
      const id = key;
      const name = pdfFiles[key].name;
      const pdfFilePath = pdfFiles[key].pdfFilePath;

      const createPdfAction = {
        type: types.UPSERT_PDFFILE,
        payload: {
          id: id,
          name: name,
          pdfFilePath: pdfFilePath,
        },
      };
      store.dispatch(createPdfAction);
    });

    const mapKeys = Object.keys(maps);
    debugLog('refresh.ts - mapKeys: ' + JSON.stringify(mapKeys, null, 2));

    for (const i in mapKeys) {
      const id = mapKeys[i];
      const initialCenterLatWeb = maps[id].initialCenterLatWeb;
      const initialCenterLonWeb = maps[id].initialCenterLonWeb;
      const initialZoomWeb = maps[id].initialZoomWeb;
      const name = maps[id].name;
      const maxZoomFactor = maps[id].maxZoomFactor;
      const minZoomFactor = maps[id].minZoomFactor;
      const neCornerLat = maps[id].neLat;
      const neCornerLon = maps[id].neLon;
      const swCornerLat = maps[id].swLat;
      const swCornerLon = maps[id].swLon;

      debugLog('refresh.ts - mapKeyId : ' + mapKeys[i]);
      const mapJSONURL = `${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}/contents/map/${mapKeys[i]}/map.json`;
      debugLog('refresh.ts - mapJSONURL : ' + mapJSONURL);

      let mapJson = {};
      // @ts-ignore
      response = await axios.get(mapJSONURL);
      mapJson = response.data;

      const createMapAction = {
        type: types.UPSERT_MAP,
        payload: {
          id: id,
          initialCenterLatWeb: initialCenterLatWeb,
          initialCenterLonWeb: initialCenterLonWeb,
          initialZoomWeb: initialZoomWeb,
          name: name,
          maxZoomFactor: maxZoomFactor,
          minZoomFactor: minZoomFactor,
          neCornerLat: neCornerLat,
          neCornerLon: neCornerLon,
          swCornerLat: swCornerLat,
          swCornerLon: swCornerLon,
          mapJson: mapJson,
        },
      };
      store.dispatch(createMapAction);
    }
    debugLog('refresh.ts - REDUX is populated with an experience');

    store.dispatch(
      experienceManager_updateStore({ key: ExperienceManagerKeys.loadingExperienceId, value: experienceJson.id })
    );
  } catch (error: any) {
    debugLog(`refresh.ts - CATCH ERROR`);
    debugLog(JSON.stringify(error, null, 2));
  }
}
