// https://redux.js.org/usage/configuring-your-store#the-solution-configurestore
import { composeWithDevTools } from 'redux-devtools-extension';
import { createReducer } from 'redux-orm';

import { appReducer } from './features/app/slice';
import { experienceManagerReducer } from './features/experienceManager/slice';
// import { experienceReducer } from './features/experience/slice';
// import { navNodeReducer } from './features/navNode/slice';

import orm from './orm';
import { applyMiddleware, combineReducers, configureStore } from '@reduxjs/toolkit';

const middlewares: never[] = [];

const rootReducer = combineReducers({
  orm: createReducer(orm),
  // app: appReducer,
  // @ts-ignore
  // experience: experienceReducer,
  // @ts-ignore
  experienceManager: experienceManagerReducer,
  // @ts-ignore
  // navNode: navNodeReducer,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

// @ts-ignore
store.close = () => store.dispatch(END);

export default store;
