import { attr, Model } from 'redux-orm';
import { types } from '../../../services/actionTypes';

interface MapFields {
  id: string;
  initialCenterLatWeb: number;
  initialCenterLonWeb: number;
  initialZoomWeb: number;
  maxZoomFactor: number;
  minZoomFactor: number;
  name: string;
  neCornerLat: number;
  neCornerLon: number;
  swCornerLat: number;
  swCornerLon: number;
  mapJson: string;
}

class Map extends Model<typeof Map, MapFields> {
  // @ts-ignore
  // eslint-disable-next-line no-shadow
  static reducer(action, Map, _session) {
    switch (action.type) {
      case types.DELETE_MAP:
        Map.withId(action.payload.id).delete();
        break;
      case types.UPSERT_MAP:
        Map.upsert(action.payload);
        break;
      default:
        break;
    }
  }
}
Map.modelName = 'Map' as const;

Map.fields = {
  id: attr({ getDefault: () => null }),
  initialCenterLabWeb: attr({ getDefault: () => null }),
  initialCenterLonWeb: attr({ getDefault: () => null }),
  initialZoomWeb: attr({ getDefault: () => null }),
  name: attr({ getDefault: () => 'Default NAME' }),
  maxZoomFactor: attr({ getDefault: () => null }),
  minZoomFactor: attr({ getDefault: () => null }),
  neCornerLat: attr({ getDefault: () => null }),
  neCornerLon: attr({ getDefault: () => null }),
  swCornerLat: attr({ getDefault: () => null }),
  swCornerLon: attr({ getDefault: () => null }),
  mapJson: attr({ getDefault: () => 'Empty Map Json' }),
};

Map.options = {
  idAttribute: 'id' as const,
};

export default Map;
