import {ORM} from 'redux-orm';

import Experience from './features/experience/model';
import Map from './features/content/map/model';
import NavNode from './features/navNode/model';
import Page from './features/content/page/model';
import Pano from './features/content/pano/model';
import PdfFile from './features/content/pdfFile/model';

const orm = new ORM({
  stateSelector: (state: any) => state.orm,
});

orm.register(Experience, Map, NavNode, Page, Pano, PdfFile);

export default orm;
