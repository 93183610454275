import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import { constants } from '../../../services/constants';
import orm from '../../../orm';
import { store } from '../../../configureStore';

import { NavNodeTypes } from '../../../services/enums';

import { Button, Container, Row } from 'react-bootstrap';
import { applyWebMenuDesign } from './utils/webMenuDesign';
import { debug } from 'console';

const DEBUG: boolean = true;
const debugLog = (str: string) => {
  if (DEBUG) {
    console.log(str);
  }
};

type Props = {
  navNodeId: string;
};

const MenuComponent = (props: Props) => {
  // const MenuComponent = (props: MenuComponentScreenProps) => {
  debugLog('MenuComponent: NavNode:MenuComponent with props: ' + JSON.stringify(props, null, 2));

  const navigate = useNavigate();
  const { navNodeId } = props;

  const width = window.innerWidth;
  debugLog('MenuComponent width : ' + width);
  const height = window.innerHeight;
  debugLog('MenuComponent height : ' + height);

  // TODO:  send to Brianne
  // const isBigScreen: boolean = useMediaQuery({ query: '(min-width: 1824px)' });
  // const isDesktopOrLaptop: boolean = useMediaQuery({ query: '(min-width: 1224px)' });

  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  // const isTabletOrMobile: boolean = useMediaQuery({ query: '(max-width: 1224px)' });
  // const isMobile = useMediaQuery({ maxWidth: 767 });

  // const isPortrait: boolean = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina: boolean = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  let isDesktopOrLaptop: boolean = useMediaQuery({ query: '(min-width: 1224px)' });
  let isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1223 });
  let isMobile = useMediaQuery({ maxWidth: 767 });

  isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
  isTablet = useMediaQuery({ minDeviceWidth: 768, maxDeviceWidth: 1223 });
  isMobile = useMediaQuery({ maxDeviceWidth: 767 });
  // isMobile = useMediaQuery({ maxDeviceWidth: 1200 });

  const isBigScreen: boolean = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile: boolean = useMediaQuery({ query: '(max-width: 1224px)' });

  const isPortrait: boolean = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina: boolean = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  debugLog(`***************************************************`);
  debugLog(`isDesktopOrLaptop ${isDesktopOrLaptop}`);
  debugLog(`isBigScreen ${isBigScreen}`);
  debugLog(`isTabletOrMobile ${isTabletOrMobile}`);
  debugLog(`isTablet ${isTablet}`);
  debugLog(`isMobile ${isMobile}`);
  debugLog(`isPortrait ${isPortrait}`);
  debugLog(`isRetina ${isRetina}`);
  debugLog(`***************************************************`);

  const session = orm.session(store.getState().orm);
  const { Experience, NavNode } = session;
  // @ts-ignore
  const navNode = NavNode.withId(navNodeId);
  const contentId = navNode.contentId;
  const experienceId = navNode.experienceId;
  // @ts-ignore
  const experience = Experience.withId(experienceId);

  debugLog('MenuComponent - navNodeId : ' + navNodeId);
  debugLog('MenuComponent - navNode: ' + JSON.stringify(navNode, null, 2));
  debugLog('MenuComponent - contentId : ' + contentId);
  debugLog('MenuComponent - experienceId : ' + experienceId);
  debugLog('MenuComponent - experience : ' + JSON.stringify(experience, null, 2));

  // debugLog('MenuComponent - experience.backgroundImageFile : ' + experience.backgroundImageFile);
  debugLog('MenuComponent - experience.backgroundImageFile : ' + experience.backgroundImageFile);
  debugLog('MenuComponent - navNode.backgroundImageFile : ' + navNode.backgroundImageFile);
  debugLog('MenuComponent - navNode : ' + JSON.stringify(navNode, null, 2));

  let mainItems: (typeof NavNode)[] = [];
  let footerItems: (typeof NavNode)[] = [];
  navNode.children.toModelArray().forEach((element: any) => {
    switch (element.childContext.slot) {
      case NavNodeTypes.footer:
        footerItems.push(element);
        break;
      case NavNodeTypes.main:
        mainItems.push(element);
        break;
      default:
        throw new Error(`Handle this: ${element}`);
    }
  });

  const menuAttrs = {
    // values defined in CMS
    menuBackgroundColor: navNode.menuAttrs.menuBackgroundColor,

    menuItemAspectRatio: navNode.menuAttrs.menuItemAspectRatio,
    menuItemColumns: navNode.menuAttrs.menuItemColumns,

    menuItemVerticalGap: navNode.menuAttrs.menuItemGap,

    menuTextAlignHorizontal: navNode.menuAttrs.menuTextAlignHorizontal,
    menuTextAlignVertical: navNode.menuAttrs.menuTextAlignVertical,
    menuTextBackgroundColor: navNode.menuAttrs.menuTextBackgroundColor,
    menuTextColor: navNode.menuAttrs.menuTextColor,
    menuTextFontFamily: navNode.menuAttrs.menuTextFontFamily,
    // menuTextFontFamily: 'Raleway-ExtraLightItalic',
    menuTextFontSize: navNode.menuAttrs.menuTextFontSize,
    menuTextPadding: navNode.menuAttrs.menuTextPadding,

    // values defined for phones
    phoneColumns: navNode.menuAttrs.menuItemPhoneColumns,
    phoneMaxColumns: navNode.menuAttrs.menuItemPhoneMaxColumns,
    phoneAspectRatio: navNode.menuAttrs.menuItemPhoneAspectRatio,

    // values defined for tablets
    tabletColumns: navNode.menuAttrs.menuItemTabletColumns,
    tabletMaxColumns: navNode.menuAttrs.menuItemTabletMaxColumns,
    tabletAspectRatio: navNode.menuAttrs.menuItemTabletAspectRatio,

    // values defined for web
    webColumns: navNode.menuAttrs.menuItemWebColumns,
    webMaxColumns: navNode.menuAttrs.menuItemWebMaxColumns,
    webAspectRatio: navNode.menuAttrs.menuItemWebAspectRatio,

    // use for quick testing
    // webColumns: 3,
    // webMaxColumns: 6,
    // webAspectRatio: 1.0,

    // webColumns: navNode.menuAttrs.menuItemTabletColumns,
    // webMaxColumns: navNode.menuAttrs.menuItemTabletMaxColumns,
    // webAspectRatio: navNode.menuAttrs.menuItemTabletAspectRatio,

    // values defined in the mobile app
    menuItemHorizontalGap: navNode.menuAttrs.menuItemGap,
    calculatedButtonWidth: -1,
    calculatedButtonHeight: -1,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  };

  debugLog('MenuComponent - menuAttrs : ' + JSON.stringify(menuAttrs, null, 2));
  debugLog('navNode.menuAttrs : ' + JSON.stringify(navNode.menuAttrs, null, 2));

  // let childContext = {
  //   slot: 'main',
  //   sortOrder: 1,
  //   backgroundColor: '255,10,0,0.3',
  //   backgroundImageFile: 'contents/image/198.JPG',
  //   textBackgroundColor: '255,10,0,0.3',
  //   textColor: '0,255,0,0.8',
  // };

  // @ts-ignore
  mainItems.sort((a, b) => (a.childContext.sortOrder < b.childContext.sortOrder ? -1 : 1));
  // @ts-ignore
  footerItems.sort((a, b) => (a.childContext.sortOrder < b.childContext.sortOrder ? -1 : 1));

  // menuAttrs.menuBackgroundColor = '150, 150, 150, 1.0';

  // menuAttrs.menuItemAspectRatio = 1.5;
  // menuAttrs.menuItemColumns = 1;
  // menuAttrs.menuItemVerticalGap = 10;
  // menuAttrs.menuItemHorizontalGap = 10;

  // menuAttrs.menuTextAlignHorizontal = 'center';
  // menuAttrs.menuTextAlignVertical = 'flex-end';
  // menuAttrs.menuTextBackgroundColor = '255, 255, 255, 0.4';
  // menuAttrs.menuTextColor = '0, 255, 0, 0.5';
  // menuAttrs.menuTextFontFamily = 'Raleway-BoldItalic';
  // menuAttrs.menuTextFontSize = 32;
  // menuAttrs.menuTextPadding = 1;

  // menuAttrs.phoneColumns = 2;
  // menuAttrs.phoneMaxColumns = 4;
  // menuAttrs.phoneAspectRatio = 2.25;

  // menuAttrs.tabletColumns = 3;
  // menuAttrs.tabletMaxColumns = 3;
  // menuAttrs.tabletAspectRatio = 1.25;

  // menuAttrs.webColumns = 3;
  // menuAttrs.webMaxColumns = 3;
  // menuAttrs.webAspectRatio = 1.25;

  debugLog('We are using:  START  +++++++++++++++++++++++++++++++++++++++++++++++++++++++');

  debugLog(`***************************************************`);
  debugLog(`isDesktopOrLaptop ${isDesktopOrLaptop}`);
  debugLog(`isBigScreen ${isBigScreen}`);
  debugLog(`isTabletOrMobile ${isTabletOrMobile}`);
  debugLog(`isTablet ${isTablet}`);
  debugLog(`isMobile ${isMobile}`);
  debugLog(`isPortrait ${isPortrait}`);
  debugLog(`isRetina ${isRetina}`);
  debugLog(`***************************************************`);


  if (isDesktopOrLaptop) {
    menuAttrs.menuTextFontSize *= 1.6;
  }

  if (isTablet) {
    menuAttrs.menuTextFontSize *= 1.4;
    menuAttrs.webAspectRatio = menuAttrs.tabletAspectRatio;
    menuAttrs.webColumns = menuAttrs.tabletColumns;
    menuAttrs.webMaxColumns = menuAttrs.tabletMaxColumns;
  }

  if (isMobile) {
    menuAttrs.menuTextFontSize *= 1;
    menuAttrs.webAspectRatio = menuAttrs.phoneAspectRatio;
    menuAttrs.webColumns = menuAttrs.phoneColumns;
    menuAttrs.webMaxColumns = menuAttrs.phoneMaxColumns;
  }



  applyWebMenuDesign(menuAttrs);

  debugLog('MenuComponent.tsx - calculated menuAttrs.menuItemColumns : ' + menuAttrs.menuItemColumns);

  // store mainItems in a 2D array (render row that renders row elements)
  // number of elements in a row = menuAttrs.menuItemColumns
  const mainMenuItems: any[][] = [];
  const footerMenuItems: any[][] = [];

  menuAttrs.menuItemHorizontalGap =
    (width - menuAttrs.calculatedButtonWidth * menuAttrs.menuItemColumns) / (menuAttrs.menuItemColumns + 1);

  debugLog('RE-CALCULATED menuAttrs : ' + JSON.stringify(menuAttrs, null, 2));

  debugLog('*************************************************');
  debugLog('*************************************************');
  let menuRow: any[] = [];
  for (let index = 0; index < mainItems.length; index++) {
    // debugLog('menuRow index : ' + index);
    // menuRow.push(mainItems[index]);
    debugLog('*************************************************');
    debugLog('*************************************************');
    debugLog(`mainItems[${index}].type = ${mainItems[index]}`);

    // @ts-ignore
    if (mainItems[index].type !== 'hidden') {
      menuRow.push(mainItems[index]);
    }
    if ((index + 1) % menuAttrs.menuItemColumns === 0 || index === mainItems.length - 1) {
      mainMenuItems.push(menuRow);
      menuRow = [];
    }
  }

  debugLog('******************************************************************************');
  debugLog('******************************************************************************');
  debugLog('******************************************************************************');
  debugLog('menuAttrs.menuItemColumns : ' + menuAttrs.menuItemColumns);
  debugLog('MenuComponent ARRAY mainMenuItems :' + JSON.stringify(mainMenuItems, null, 2));
  debugLog('******************************************************************************');

  debugLog('navNode.backgroundImageFile');
  // debugLog(`file://${experienceRootDir + navNode.backgroundImageFile}`);

  // const backgroundImageURL = `${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}/contents/image/${navNode.backgroundImageFile}`;
  const backgroundImageURL = `${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}/${navNode.backgroundImageFile}`;
  // debugLog(`process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL :  ${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}`);
  // debugLog('navNode.backgroundImageFile : ' + JSON.stringify(navNode.backgroundImageFile, null, 2));
  // debugLog('backgroundImageURL : ' + JSON.stringify(backgroundImageURL, null, 2));

  const selectChild = (childNavNodeId: string) => {
    // store.dispatch(navNode_select({ navNodeId: childNavNodeId }));
    // navigate(`/navNode/${childNavNodeId}`);
    navigate(`${process.env.REACT_APP_BASENAME}#nn=${childNavNodeId}`);
  };

  return (
    <div>
      <div
        // className="vh-100 vw-100 d-flex flex-column"
        // className="vh-100  "
        // className="vh-100 d-flex "
        style={{
          // backgroundImage: `${backgroundImageURL}`,
          backgroundImage: `url(${backgroundImageURL})`,
          // backgroundPosition: 'center',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          // width: '100vw !important ',
          // minWidth: '100vw !important ',
          height: '100%',
          // height: '100vh !important',
          minHeight: '100vh',
          // // minHeight: '500px',
          // border: 'red solid 4px',
        }}
      >
        {/* <img
        src={`${backgroundImageURL}`}
        style={{
          backgroundImage: `${backgroundImageURL}`,
          backgroundSize: 'cover',
          backgroundColor: 'transparent',
          border: 'orange solid 4px',
          height: height,
          width: width,
        }}
        alt="alt test"
      ></img> */}
        {/* <div
      style={{
        backgroundImage: `${backgroundImageURL}`,
        backgroundSize: 'cover',
        //backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        border: 'orange solid 4px',
        height: height,
        width: width,
      }}
      > */}
        {/* <div
          // fluid
          // className="has-bg-img  bg-img bg-cover"
          style={{
            border: 'green solid 4px',
            // backgroundImage: `${backgroundImageURL}`,
            backgroundImage: backgroundImageURL,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh',
          }}
        > */}
        <div
          className="p-3 d-flex justify-content-center align-items-center"
          style={{
            // border: 'red solid 4px',
            width: '100vw',
            minHeight: '10vh',
            height: '150px',
          }}
        >
          <p
            style={{
              fontSize: menuAttrs.menuTextFontSize * 1.3,
              fontFamily: menuAttrs.menuTextFontFamily,
              color: `rgba(${menuAttrs.menuTextColor})`,
            }}
          >
            {navNode.name}
          </p>
        </div>
        {mainMenuItems.map((menuRowItems) => (
          <Row
            key={Math.random()}
            style={{
              backgroundSize: 'cover',
              // backgroundColor: `"rgba(${menuAttrs.menuBackgroundColor})"`,
              // border: '5px solid rgba(0, 0, 255, 0.8)',
              // backgroundColor: `rgba(${menuAttrs.menuBackgroundColor})`,
              backgroundColor:
                menuAttrs.menuBackgroundColor !== null && menuAttrs.menuBackgroundColor !== undefined
                  ? `rgba(${menuAttrs.menuBackgroundColor})`
                  : ` rgba(0, 0, 255, 0.0)`,

              display: 'flex',
              flexDirection: 'row',
              // alignContent: 'center',
              // justifyContent: 'left',
              justifyContent: 'space-evenly',
              // justifyContent: 'center',
              // justifyContent: 'space-between',
              // alignContent: 'space-around',
              // alignContent: 'space-between',
              // alignItems: 'normal',

              // minHeight: menuAttrs.menuItemRowHeight,
              // minHeight: menuItemWidth,
              // minHeight: height,
              // minHeight: menuItemWidth * menuAttrs.menuItemAspectRatio,
            }}
          >
            {menuRowItems.map((menuItem) => (
              <React.Fragment key={menuItem.id}>
                <Button
                  // rounded={0}
                  onClick={() => selectChild(menuItem.id)}
                  style={{
                    height: menuAttrs.calculatedButtonHeight,
                    width: menuAttrs.calculatedButtonWidth,
                    backgroundImage: `url(${process.env.REACT_APP_EXPERIENCE_CONTENT_BASE_URL}/${menuItem.childContext.backgroundImageFile})`,
                    backgroundSize: 'cover',
                    // margin: 0,
                    // marginLeft: menuAttrs.menuItemHorizontalGap,
                    marginTop: menuAttrs.menuItemVerticalGap,
                    // marginBottom: 0,
                    color: `rgba(${menuItem.childContext.textColor || menuAttrs.menuTextColor})`,
                    fontFamily: menuAttrs.menuTextFontFamily,
                    // fontFamily: 'Raleway-BoldItalic',
                    fontSize: menuAttrs.menuTextFontSize,
                    lineHeight: menuAttrs.menuTextFontSize * 0.05,
                    // lineHeight: 'normal',
                    // padding: menuAttrs.menuTextPadding,
                    paddingBottom: menuAttrs.menuTextPadding,
                    // textAlign: menuAttrs.menuTextAlignHorizontal,
                    // textAlign: 'center',
                    // alignItems: 'flex-end',
                    // alignItems: menuAttrs.menuTextAlignHorizontal,
                    alignItems: menuAttrs.menuTextAlignVertical,
                    border: 0,
                    borderRadius: 0,
                    display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // justifyContent: menuAttrs.menuTextAlignVertical,
                    justifyContent: menuAttrs.menuTextAlignHorizontal,
                    // backgroundColor: `rgba(${menuItem.childContext.backgroundColor})`,
                    backgroundColor:
                      menuItem.childContext.backgroundColor !== null &&
                        menuItem.childContext.backgroundColor !== undefined
                        ? `rgba(${menuItem.childContext.backgroundColor})`
                        : ` rgba(0, 0, 255, 0.0)`,
                    // backgroundColor:
                    //   `${menuItem.childContext.backgroundImageFile})` !== null &&
                    //   `${menuItem.childContext.backgroundColor}` !== null
                    //     ? `rgba(1,1,1,0.0)`
                    //     : `rgba(${menuItem.childContext.backgroundColor})`,
                    //backgroundColor: `rgba(50, 100, 50, 0.0)`,
                    // backgroundColor: `null`,
                    // backgroundColor: `"rgba(${
                    //   menuItem.childContext.textBackgroundColor || menuAttrs.menuTextBackgroundColor
                    // })"`,
                  }}
                >
                  {/* <ImageBackground
                      key={menuItem.id}
                      source={{ uri: `file://${experienceRootDir + menuItem.childContext.backgroundImageFile}` }}
                      // source={{uri: 'file://' + source, scale: 1}}
                      resizeMode="cover"
                      style={{
                        width: menuAttrs.calculatedButtonWidth,
                        height: menuAttrs.calculatedButtonWidth * menuAttrs.menuItemAspectRatio,
                        flexGrow: 0,
                        flexShrink: 1,
                        flexBasis: 'auto',
                        flexDirection: 'column',
                        //justifyContent: 'flex-end',
                        justifyContent: menuAttrs.menuTextAlignVertical,
                        marginTop: -11,
                        marginBottom: -11,
                      }}
                    > */}
                  {/* <Text
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        style={{
                          backgroundColor: `"rgba(${
                            // menuItem.childContext.textBackgroundColor || menuAttrs.menuTextBackgroundColor
                            menuItem.childContext.textBackgroundColor || menuAttrs.menuTextBackgroundColor
                          })"`,
                          color: `rgba(${menuItem.childContext.textColor || menuAttrs.menuTextColor})`,
                          fontSize: menuAttrs.menuTextFontSize,
                          fontFamily: menuAttrs.menuTextFontFamily,
                          padding: menuAttrs.menuTextPadding,
                          textAlign: menuAttrs.menuTextAlignHorizontal,
                          // marginBottom: menuAttrs.menuItemVerticalGap,
                          marginBottom: 0,
                        }}
                        // color=`rgba(${menuItem.childContext.textColor})`
                      >
                        {menuItem.name}
                      </Text> */}
                  {/* </ImageBackground> */}
                  {menuItem.name}
                </Button>
              </React.Fragment>
            ))}
          </Row>
        ))}
        <div style={{ height: '30px' }}></div>
      </div>
    </div>
  );
};

export default MenuComponent;
