import { createSelector } from 'reselect';

const experienceManagerSelector = (state: any) => state.experienceManager;

export const experienceManagerLoadingExperienceIdSelector = createSelector(
  experienceManagerSelector,
  (experience) => experience.loadingExperienceId
);

export const experienceManagerExperienceManagerStatusSelector = createSelector(
  experienceManagerSelector,
  (experience) => experience.experienceManagerStatus
);

export default experienceManagerExperienceManagerStatusSelector;
